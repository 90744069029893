<template>
    <div class="dcLogin">
        <b-container>
            <b-row>
                <b-col sm="12" lg="6">
                    <h4 class="black mt-2">Inserisci il Buono e prenota il tuo viaggio</h4>
                    <br>
                    <p>
                        Troverai lo sconto disponibile al momento del pagamento
                    </p>
                    <p>
                        <ul class="ml-3">
                            <li>
                                Se il Buono ha un valore inferiore al conto finale dovrai saldare la differenza con carta di credito. Non potrai pagare la differenza con il tuo credito welfare.
                            </li>
                            <li>
                                Se il Buono ha un valore superiore all'ammontare del pagamento, potrai utilizzare il valore residuo per una futura prenotazione. Riceverai una email di riepilogo.
                            </li>
                            <li>
                                All'interno potrai caricare altri Buoni in tuo possesso e cumulare lo sconto.
                            </li>
                        </ul>
                    </p>
                </b-col>
                <b-col sm="12" lg="6">
                    <b-form-group :state="validateFields()">
                        
                        <div class="loginBlockUp">
                            <h6 class="black text-center mb-3">Inserisci il Buono sconto</h6>
                            <p class="loginDc mb-0">
                                <!--<label class="d-block text-left"><small>Codice sconto</small></label>-->
                                <b-form-input ref="discountCode" :required="true" v-model="discountCode" type="text" placeholder="Es: XXXXXXXXXXXXXXXX" data-e2e="discountCodeInput"/>
                            </p>

                            <p class="loginPromoshopping mt-2 mb-2">
                                <!--Puoi caricare anche buoni <span class="img"><img src="../../../assets/logoPromoshopping.png" /></span>-->
                            </p>

                            <p>
                                <!--<label class="d-block text-left"><small>Nome</small></label>-->
                                <b-form-input ref="name" :required="false" v-model="name" type="text" placeholder="Nome" />
                            </p>

                            <p>
                                <!--<label class="d-block text-left"><small>Cognome</small></label>-->
                                <b-form-input ref="surname" :required="false" v-model="surname" type="text" placeholder="Cognome" />
                            </p>

                            <p>
                                <!--<label class="d-block text-left"><small>Email</small></label>-->
                                <b-form-input ref="email" :required="false" v-model="email" type="email" placeholder="Email" />
                            </p>

                            <div class="loginBlockCheckbox">
                                <div class="d-flex">
                                    <b-form-checkbox ref="terms" required v-model="checkboxTerms" type="checkbox" name="checkbox" id="checkboxTerms" />
                                    <label for="checkboxTerms">
                                        <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                            <span class="secondary">Accetto le condizioni d'uso</span>
                                        </router-link>
                                    </label>
                                </div>

                                <div class="d-flex">
                                    <b-form-checkbox ref="privacy" required v-model="checkboxPrivacy" type="checkbox" name="checkbox" id="checkboxPrivacy" />
                                    <label for="checkboxPrivacy">
                                        <router-link :to="{name: 'privacy-policy'}" target="_blank" class="nounderline">
                                            <span class="secondary">Accetto le condizioni di Privacy</span>
                                        </router-link>
                                    </label>
                                </div>
                            </div>

                            <div class="loginBlockLogin">
                                <div class="loginButton" v-if="!loading">
                                    <b-button v-if="recaptchaEnabled" :disabled="!validateFields()" @click="recaptcha">CARICA</b-button>
                                    <b-button v-else :disabled="!validateFields()" @click="dcLogin">CARICA</b-button>
                                </div>
                                <Spinner v-else />
                            </div>

                        </div>

                    </b-form-group>

                    <!--
                    <div v-if="data == '' || data == undefined">
                        C'è stato un problema, devi accedere dal portale Jointly
                    </div>
                    
                    <div v-else>
                        Accesso in corso...
                        <br><br>
                        <Spinner v-if="loading" />
                    </div>
                    -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Api from '../../../api'
import Spinner from '../../atoms/spinner'

export default {
    name: 'dc-sso',
    components:
    {
        Spinner,
    },
    props:
    {
        discountCodeUrl: {
          type: String,
          default: '',
        },
        nameUrl: {
          type: String,
          default: '',
        },
        surnameUrl: {
          type: String,
          default: '',
        },
        emailUrl: {
          type: String,
          default: '',
        },
    },
    data()
    {
        return {
            //data: '',
            loading: false,
            name: '',
            surname: '',
            email: '',
            discountCode: '',
            checkboxTerms: false,
            checkboxPrivacy: false,
            recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
            recaptchaToken: '',
        }
    },
    mounted()
    {
        if(Vue.prototype.$config.recaptcha.enabled)
        {
            Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
        }

        // http://localhost:8080/ho-un-buono/oRQs4HarkHcfNvka/giuseppe/cardamone/test@email.it
        if(this.discountCodeUrl !== '') {
            this.discountCode = this.discountCodeUrl
        }
        if(this.nameUrl !== '') {
            this.name = this.nameUrl
        }
        if(this.surnameUrl !== '') {
            this.surname = this.surnameUrl
        }
        if(this.emailUrl !== '') {
            this.email = this.emailUrl
        }
    },
    methods:
    {
        async recaptcha() {

            this.loading = true

            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            // Do stuff with the received token.
            this.recaptchaToken = token
            this.dcLogin()
        },
        validateFields()
        {
            if(Object.keys(this.$refs).length == 0) return false
            return this.mixinValidateFields([
                {
                    ref: this.$refs.discountCode,
                    type: 'discountCode',
                    value: this.discountCode,
                },
                {
                    ref: this.$refs.name,
                    type: 'firstName',
                    value: this.name,
                    optional: true,
                },
                {
                    ref: this.$refs.surname,
                    type: 'lastName',
                    value: this.surname,
                    optional: true,
                },
                {
                    ref: this.$refs.email,
                    type: 'email',
                    value: this.email,
                    optional: true,
                },
                {
                    ref: this.$refs.privacy,
                    type: 'checkbox',
                    value: this.checkboxPrivacy,
                },
                {
                    ref: this.$refs.terms,
                    type: 'checkbox',
                    value: this.checkboxTerms,
                }
            ])
        },
        dcLogin()
        {
            let _this = this

            _this.loading = true
            Api.dcLogin({
                discountCode: _this.discountCode,
                name: _this.name,
                surname: _this.surname,
                email: _this.email,
                recaptcha: _this.recaptchaToken,
            })
            .then((results) => {
                if(results !== false)
                {

                    // ho spostato questo richiamo in config appConfig
                    // facendo passare il codice sconto tramite token integration.loginDiscountCode
                    // così ho maggior controllo (se facevo un login con codice sconto e poi un sso normale
                    // "restava in memoria" il codice sconto)
                    //this.mixinSendMutation('setDiscountCodes',[_this.discountCode])

                    window.location.href = "/?token=" + results.token
                }
                else
                {
                    _this.loading = false
                }
            })
        }
    },
}
</script>


<style lang="scss">

    
    
    

    .dcLogin
    {
        display:block;
        width:100%;
        max-width:1200px;
        margin:0 auto;
        margin-bottom:6rem;
        padding-top: 50px;

        @include media-breakpoint-up(lg) {
            padding-top: 250px;
        }

        ul
        {
            display:block;
            margin-top:1rem;
        }
        li
        {
            list-style:disc;
            margin-top:1rem;
        }
        .loginBlockUp
        {
            margin:auto;
            padding-bottom:2rem;
            max-width: 400px;
        }
        .loginBlockCheckbox
        {
            margin:auto;
            max-width: 300px;
            padding-bottom:2rem;
            *
            {
                //font-size:16px;
            }
        }
        .loginPromoshopping
        {
            text-align:center;
            padding-left:1rem;
            padding-right:1rem;
            display:flex;
            justify-content: center;
            align-items: center;
            font-size:14px;
            margin-top:2rem;
            //margin-bottom:0;
            .img
            {
                max-width: 130px;
                padding:0 0.5rem 0.25rem 0.5rem;
            }
        }
        .loginBlockLogin
        {
            display:flex;
            min-width:100%;
            .loginDc
            {
                flex:1;
            }
            .loginButton
            {
                display:block;
                min-width:100%;
                //min-width: 160px;
                //margin-left: 2rem;
            }
            button
            {
                display: block;
                width:100%;
            }
        }
    }
</style>
<template>
    <div id="page" class="terms">
        <b-container>
            <section>
                <h1 class="text-center">Termini e condizioni</h1>
                <p>
                  <b>
                    SI PREGA DI LEGGERE ATTENTAMENTE QUESTI TERMINI E CONDIZIONI. ESSI CONTENGONO IMPORTANTI INFORMAZIONI SUL FUNZIONAMENTO DEL SERVIZIO
                    {{ uppercaseBrand }} E SULLE LIMITAZIONI DI RESPONSABILITÀ
                  </b>
                </p>
            </section>

            <section>
                <h2 class="text-center">DEFINIZIONI</h2>

                <p>
                  L’espressione <b>SITO WEB {{ uppercaseBrand }}</b> indica i software accessibili all’indirizzo <a :href="host">{{host}}</a> che permettono la visualizzazione e la prenotazione delle disponibilità di pernotti e servizi turistici.
                </p>

                <p>
                  Il termine <b>DISPONIBILITA’</b> indica le proposte di pernotto e servizi turistici visualizzabili e prenotabili sul Sito Web
                  {{ site.brand }}. Le disponibilità sono proposte dal Sito Web {{ site.brand }} in tempo reale, nel momento di utilizzo del Sito Web {{ site.brand }} stesso da parte dell’Utente. Le Disponibilità vengono rese disponibili dagli albergatori o fornitori di servizi turistici tramite i partner commerciali di {{ site.brand }} (Online Travel Agencies, OTA) e sono rese disponibili da {{ site.brand }} ai propri Utenti tramite un accordo di partnership con i siti partner stessi (OTA). Le disponibilità non sono quindi rese disponibili a seguito di una  contrattualizzazione diretta  tra {{ site.brand }} e le strutture ricettive stesse. La struttura commerciale definita configura {{ site.brand }} quale Agenzia Viaggi Online.
                </p>

                <p>
                  I termini <b>NOI</b>, <b>SOCIETÀ</b> e <b>{{ uppercaseBrand }}</b>  si riferiscono ad un servizio online fornito da Salabam srl, una società a responsabilità limitata Italiana con sede in Via del Porto 50, 47841 Cattolica, RN, Italia.
                </p>

                <p>
                  L'Utente è la persona, che tramite {{ site.brand }} effettua la prenotazione di un servizio di viaggio.
                </p>

                <p>
                  <b>PORTALE PARTNER</b> indica un sito web convenzionato con {{ site.brand }} e Salabam srl il quale tramite un meccanismo tecnologico di autenticazione sicura, chiamato Single Sign-On (SSO), attribuisce al singolo utente un credito virtuale riconosciuto da {{ site.brand }} e spendibile sul sito {{ site.brand }}.
                </p>

                <p>
                  Il termine <b>PRENOTAZIONE</b> indica l’operazione con la quale l’Utente prenota un pernotto tramite i servizi online forniti dal Sito Web {{ site.brand }} utilizzando, quale forma di pagamento il Credito personale a lui messo a disposizione, sul portale partner stesso, e riconosciuto ed accettato come strumento di pagamento da {{ site.brand }}.
                </p>

                <p>
                  L’espressione <b>HOTEL VOUCHER {{ uppercaseBrand }}</b> indica il documento inviato via email all’Utente a seguito della sua Prenotazione e recante il riepilogo dettagliato dei servizi prenotati nonchè un riferimento univoco alla Prenotazione stessa.
                </p>

                <p>
                  Con l’espressione <b>STRUTTURA RICETTIVA</b> si intende qualsiasi fornitore di servizi di pernotto. A titolo eminentemente esemplificativo e non esaustivo rientrano nella categoria gli hotel, i bed&breakfast, gli appartamenti, gli agriturismi, i villaggi turistici, i resort, ecc.
                </p>

            </section>

            <section>
              <h2 class="text-center">INDICAZIONI GENERALI</h2>

              <p>
                <b>
                  L’utilizzo di questo sito internet è vincolato all’accettazione senza riserve dei termini, condizioni ed avvertenze ivi contenuti, in vigore al momento della prenotazione. Qualsiasi utilizzo da parte dell’utente o da parte nostra delle informazioni relative al conto personale ed alle prenotazioni effettuate tramite il nostro sito è soggetto ai seguenti termini, condizioni e notifiche. L’utilizzo del sito costituisce la sottoscrizione a tutti i summenzionati termini, condizioni ed avvertenze.
                </b>
              </p>

              <p>
                <b>
                  L’utilizzo del sito è altresì soggetto ai termini e alle condizioni applicate da Expedia Affiliate Network L.P e reperibili al seguente indirizzo <a href="http://developer.ean.com/terms/it/">http://developer.ean.com/terms/it/</a>
                </b>
              </p>

              <p>
                <b>
                  Se l’utente non accetta tali termini e condizioni non è autorizzato ad utilizzare il sito web. Per ogni controversia sarà esclusivamente competente il Foro di Rimini.
                </b>
              </p>

            </section>

            <section>
              <h2 class="text-center">FUNZIONAMENTO E SERVIZI {{ uppercaseBrand }}</h2>

              <p>
                Il Sito Web {{ site.brand }} permette all’Utente  la prenotazione di pernotti e servizi turistici. Il Sito Web {{ site.brand }} propone all’Utente disponibilità di pernotto reali e immediatamente confermabili ottenute dinamicamente da partner commerciali (Online Travel Agency, OTA).
              </p>

              <p>
                {{ site.brand }} agisce come interfaccia tra l'Utente, da una parte, e i partner commerciali di {{ site.brand }} e le Strutture ricettive e gli altri fornitori di servizi di viaggio, dall'altra. Quando l'Utente effettua una prenotazione per un Servizio di viaggio usando {{ site.brand }}, egli conclude un contratto con il relativo Fornitore dei servizi di viaggio o Struttura ricettiva per tale Servizio.
              </p>

              <p>
                L’utente riconosce ed accetta il fatto che Salabam eroghi un servizio a fronte di adeguata remunerazione del servizio stesso. Il servizio remunerato è:
              </p>

              <ol>
                <li>
                  un servizio di prenotazione di pernotti e servizi turistici agevolato da un sistema che permette all’Utente di effettuare in totale libertà ricerche su un altissimo numero di destinazioni (indefinito e nell’ordine di milioni di destinazioni), su date e numero di partecipanti
                </li>
                <li>
                  un servizio che permette a terzi (aziende o organizzazioni) di gestire i loro piani di Welfare Aziendale, o parte di questi, operazioni di promozione commerciale, piani di regalistica, di incentivazione e loyalty.
                </li>
              </ol>

            </section>

            <section>
              <h2 class="text-center">LIMITAZIONE DI RESPONSABILITA’</h2>

              <p>
                Le informazioni, i software, i prodotti e servizi pubblicati su questo sito possono presentare inaccuratezze o errori tipografici. Più specificatamente, {{ site.brand }} non garantisce l’accuratezza e declina ogni responsabilità per le inaccuratezze informative concernenti le strutture ricettive o altri fornitori, relativamente a fotografie, descrizioni delle strutture ricettive ed alla descrizione dei servizi disponibili. Le informazioni vengono comunicate a {{ site.brand }} e ai suoi fornitori, dalle strutture ricettive e altri fornitori di contenuti, in tempo reale e in via telematica. Per questo motivo le informazioni di disponibilità e le descrizioni dei servizi proposti all’Utente vengono costantemente aggiornate e modificate. {{ site.brand }} o i rispettivi fornitori possono apportare miglioramenti o modifiche al presente sito in qualsiasi momento. Esaurimento o modifiche delle disponibilità proposte possono verificarsi in qualsiasi momento senza preavviso e senza alcuna responsabilità per {{ site.brand }}.
              </p>

              <p>
                L’inclusione o la messa in vendita di qualsivoglia prodotto e servizio su questo sito non costituisce in alcun modo approvazione né raccomandazione di tali prodotti o servizi da parte di {{ site.brand }}.
              </p>

              <p>
                Le classificazioni degli hotel visualizzate sul sito hanno valore puramente orientativo e {{ site.brand }} non può garantirne l’accuratezza.
              </p>

              <p>
                  Gli operatori, le strutture ricettive e gli altri fornitori di viaggi o di altri servizi proposti sul sito {{ site.brand }} sono contraenti indipendenti e non sono agenti nè impiegati di Salabam srl. {{ site.brand }} non è responsabile delle azioni, errori, omissioni, rappresentazioni, garanzie, infrazioni o negligenze di uno dei succitati fornitori, nè di lesioni, morte, danni a proprietà personale o spese e danni che risultino dal ricorso a tali fornitori.
              </p>

              <p>
                {{ site.brand }} e Salabam srl non sono responsabili, nè emetteranno alcun rimborso, in caso di ritardo, cancellazione, overbooking, sciopero, forza maggiore o altri eventi che non dipendano dal loro controllo diretto e non sono responsabili per ogni altra spesa aggiuntiva, omissione, cambio di itinerario o azioni causati da governi o autorità.
              </p>

              <p>
                In nessun caso {{ site.brand }}, Salabam srl e i rispettivi fornitori, verranno ritenuti responsabili per qualsiasi sanzione, ne’ per qualsiasi danno diretto, incidentale, specifico o emergente causato da, o in ogni modo connesso a, l’utilizzo di questo sito, o al mancato accesso o accesso ritardato al presente sito.
              </p>

              <p>
                D’altronde {{ site.brand }} e Salabam srl non escludono o limitano in alcun modo la propria responsabilità su questioni che non possano essere oggetto di esclusione di responsabilità ai sensi della legge vigente.
              </p>

            </section>

            <section>
              <h2 class="text-center">LINK A SITI DI TERZI</h2>

              <p>
                Questo sito può contenere link ad altri siti, gestiti da società diverse da Salabam srl. Tali link sono forniti solo per riferimento. Salabam non controlla tali siti e non è responsabile del loro contenuto, né dell’utilizzo che ne fa l’Utente. Il fatto che {{ site.brand }} includa dei link ai siti summenzionati non implica in alcun modo l’approvazione del materiale presente sui siti linkati né sta ad indicare alcuna associazione con gli operatori di detti siti.
              </p>

            </section>

            <section>
              <h2 class="text-center">SOFTWARE DISPONIBILI SUL SITO</h2>

              <p>
                Qualsiasi sofware o servizio utilizzabile sul Sito Web {{ site.brand }} è coperto dal diritto di autore di Salabam srl.
              </p>

              <p>
                Senza limitazione a quanto appena dichiarato, la copia o la riproduzione del software su qualsiasi altro server o supporto per ulteriore riproduzione o diffusione è espressamente proibita.
              </p>

            </section>

            <section>
              <h2 class="text-center">ALTRI TERMINI DI UTILIZZO PER L’APPLICAZIONE {{ uppercaseBrand }} PER DISPOSITIVI PORTATILI</h2>

              <p>
                Non esiste una corrispondenza perfetta tra le funzioni del sito fruite da desktop e quelle fruite da mobile, tablet o altri dispositivi.
              </p>

            </section>

            <section>
              <h2 class="text-center">RISERVA DEI DIRITTI</h2>

              <p>
                L’utente è consapevole che tutti i diritti di proprietà intellettuale, la proprietà stessa e tutti gli interessi nell’Applicazione e nei Contenuti appartengono a Salabam srl e ai suoi fornitori. Tali diritti sono tutelati da leggi e trattati internazionali. Tutti questi diritti sono riservati.
              </p>

            </section>

            <section>
              <h2 class="text-center">INFORMAZIONI PER L’UTENTE E PER L’UTILIZZO DELL’APPLICAZIONE</h2>

              <p>
                L’elaborazione delle informazioni sull’Utente è trattata in modo conforme alla nostra Informativa sulla privacy. Utilizzando questa Applicazione, l’Utente acconsente a tale elaborazione. Si consiglia pertanto la lettura integrale della nostra <a :href="site.urlPrivacy" target="_blank">Informativa sulla privacy</a>.
              </p>

            </section>

            <section>

              <h2 class="text-center">PAGAMENTO ONLINE</h2>

              <p>Il pagamento del corrispettivo delle singole prenotazioni può avvenire tramite:</p>

              <div v-if="isVadobay">

                <ol>
                  <li>addebito diretto ed istantaneo sul conto welfare dell'utente</li>
                  <li>buono sconto {{site.brand}}</li>
                </ol>

              </div>

              <div v-else>

                <ol>
                  <li>carte di credito: {{site.brand}} accetta tutte le principali carte di credito, American Express, Mastercard, Visa</li>
                  <li v-if="etcEnabled">Ticket Compliments Edenred</li>
                  <li v-if="promoshoppingEnabled">Promoshopping</li>
                  <li>buono sconto {{site.brand}}</li>
                  <li v-if="scalapayEnabled">Scalapay</li>
                </ol>

                <br/>

                <p>Nella pagina di check-out l'utente trova tutte le possibili opzioni di pagamento.</p>

              </div>

              <p v-if="scalapayEnabled">
                L'utente che effettua la sua prenotazione e paga con Scalapay riceve il suo ordine subito e paga in 3 rate. L'utente prende atto del fatto che le rate verranno cedute a Incremento SPV S.r.l., a soggetti correlati e ai loro cessionari, e che autorizza tale cessione.
              </p>

            </section>

            <section>
              <h2 class="text-center">VARIAZIONI E CANCELLAZIONI</h2>

              <p>
                Le soluzioni di soggiorno proposte su {{ site.brand }} sono sia di tipo RIMBORSABILE sia di tipo NON-RIMBORSABILE. La cancellazione di una soluzione RIMBORSABILE genera un Buono sconto utilizzabile nell'arco di 12 mesi per una nuova prenotazione. Le soluzioni NON-RIMBORSABILI non prevedono alcun rimborso o creazione di Buoni sconto.
              </p>

            </section>

            <section>
              <h2 class="text-center">DENUNCE DI VIOLAZIONE DEL COPYRIGHT</h2>

              <p>
                Qualora l’Utente ritenga in buona fede che il materiale ospitato da {{ site.brand }} violi il proprio copyright, può inviarci, anche tramite un suo rappresentante, una comunicazione scritta che includa le informazioni richieste qui di seguito. {{ site.brand }} non prenderà in considerazione le richieste dell’Utente non compilate correttamente o incomplete. Nel caso in cui la comunicazione contenga false dichiarazioni circa il fatto che un determinato contenuto o attività costituisce una violazione, l’Utente potrebbe rispondere dei danni.
              </p>

              <ol>
                <li>
                  Elementi che consentano di identificare chiaramente l’opera protetta dal copyright che sarebbe stato violato
                </li>
                <li>
                  Elementi che consentano di identificare chiaramente il materiale che costituirebbe violazione dell’opera protetta da copyright, in modo da consentire a {{ site.brand }} di individuare tale materiale sul sito Web (ad esempio, un collegamento al suddetto materiale)
                </li>
                <li>
                  Informazioni utili per contattare l’Utente affinché {{ site.brand }} possa rispondere alla richiesta, inclusi, possibilmente, un indirizzo e-mail e un numero di telefono
                </li>
                <li>
                  Una dichiarazione con cui l’Utente afferma di “aver ritenuto in buona fede che la pubblicazione del materiale in violazione del copyright non sia autorizzata dal titolare del copyright, da un suo rappresentante o dalla legge
                </li>
                <li>
                  Una dichiarazione secondo cui “le informazioni riportate nella notifica sono precise e si dichiara sotto giuramento che la parte che presenta denuncia è autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato”
                </li>
                <li>
                  La comunicazione deve essere firmata dalla persona autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato
                </li>
              </ol>

              <p>
                L’Utente può inviare la propria comunicazione per e-mail all’indirizzo <a :href="'mailto:' + site.emailSupport">{{site.emailSupport}}</a>
              </p>

            </section>

          <section>

            <p>
              Versione rivista e in vigore dal 25 luglio 2018
            </p>

            <p>
              ©2018 Salabam SRL, Tutti i diritti riservati
            </p>

          </section>

        </b-container>
    </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: 'terms',
    metaInfo: {
      title: 'termini e condizioni',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
      ],
    },
    components: {
    },
    methods: {
    },
    data(){
      return {
        site: Vue.prototype.$config.site,
        uppercaseBrand: Vue.prototype.$config.site.brand.toUpperCase(),
        host: process.env.VUE_APP_SITE_HOST,

        isVadobay: (process.env.VUE_APP_NAME == 'vadobay'),
        etcEnabled: Vue.prototype.$config.guiSettings.integrations.etc.enabled,
        scalapayEnabled: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
        promoshoppingEnabled: (Vue.prototype.$config.guiSettings.integrations.promoshopping.enabled)? true : false,

      }
    }
  }
</script>

<style scoped lang="scss">


  ol>li{
    list-style-type: disc;
    margin-bottom: 15px;
  }



</style>
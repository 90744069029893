<template>
    <b-container class="text-center">

        <br><br>

        <div v-if="orderToken == '' || !loading">

          <span>
              Richiesta annullata, torna alla home
          </span>

        </div>

        <div v-else>


          <div v-if="loading">

            <span>
              Attendi, stiamo annullando la richiesta...
            </span>

            <Spinner />

          </div>


        </div>

        <br/><br/>

    </b-container>
</template>

<script>
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'
import Store from "@/vuex/store";
export default {
    name: 'scalapay-cancel',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            orderToken: '',
            status: '',
            loading: true,
            redirectHomeTimeout: 1500,
        }
    },
    mounted()
    {
        let _this = this

        _this.orderToken = (_this.$router.currentRoute.query.orderToken)? _this.$router.currentRoute.query.orderToken : ''
        _this.status = (_this.$router.currentRoute.query.status) ? _this.$router.currentRoute.query.status : ''

        if(_this.orderToken !== '')
        {
            _this.scalapayCancel()
        }
        else
        {
            _this.loading = false
            setTimeout(() => { _this.mixinGoTo('homepage', {}) }, _this.redirectHomeTimeout)
        }
    },
    methods:
    {
        scalapayCancel()
        {
            let _this = this

            Api.scalapayCancel({
              token: Store.state.token,
              orderToken: _this.orderToken,
              status: _this.status,
            })
            .then((results) => {

                _this.loading = false

                if(results.updateAvailabilityTo) _this.mixinSendMutation('setAvailability',results.updateAvailabilityTo)

                setTimeout(() => { _this.mixinGoTo('homepage', {}) }, _this.redirectHomeTimeout)

            })
        }
    },
}
</script>
<template>
    <div class="checklist">
        <ul>
            <li v-for="(item,index) in items" v-bind:key="index+item">
                <Checked v-if="!numbers"/>
                <span class="checklist_index" v-else>{{parseInt(index)+1}}</span>
                <span class="checklist_item" v-html="item"></span>
            </li>
        </ul>
    </div>
</template>

<script>
import Checked from './../../svg/checked'
export default {
    name: 'quote',
    components:
    {
        Checked
    },
    props:
    {
        items: Object,
        numbers: Boolean
    }
}
</script>

<style lang="scss">
    
    
    

    .checklist
    {
        ul
        {

        }
        svg,
        .checklist_index
        {
            color:$tertiary;
            font-size: 22px;
            font-weight: 700;

            width:22px;
            margin-right:12px;
        }
        .checklist_index:after
        {
            content:".";
        }
        li
        {
            display:flex;
            margin-bottom:$grid-gutter-width/2;
            span
            {
                font-size:16px;
            }
            .checklist_item
            {
                flex:1;
                width:calc(100% - 42px);
            }
        }
    }
</style>
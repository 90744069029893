<template>
    <div class="numbered">
        <ul>
            <li v-for="(item,index) in items" v-bind:key="index+item">
                <div class="heading">
                    <span class="numbered_index">{{parseInt(index)+1}}.</span>
                    <span class="numbered_item" v-html="item.title"></span>
                </div>
                <span class="numbered_item" v-html="item.content"></span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'numbered-items',
    props:
    {
        items: Object
    }
}
</script>

<style lang="scss">
    
    
    

    .numbered
    {
        margin-top:3rem;
        margin-bottom:2rem;
        li
        {
            margin-bottom: 1rem;
        }
        .heading
        {
            display: flex;
            align-items: center;
            color:$tertiary;
            font-weight: 900;
            font-size: 18px;
            margin-bottom: 12px;
            min-height: 60px;
            line-height: 120%;
        }
        .numbered_index
        {
            font-weight: 900;
            font-size: 24px;
            color:$white;
            background:$tertiary;
            width:36px;
            min-width:36px;
            height:36px;
            border-radius: 50%;
            margin-right: 8px;
            text-align: center;
            line-height: 100%;
            padding-top: 6px;
            padding-left: 4px;
        }

        @include media-breakpoint-up(lg)
        {
            ul
            {
                display:flex;
            }
            li
            {
                flex:1;
                &:nth-child(1)
                {
                    padding-right:1.5rem;
                }
                &:nth-child(2)
                {
                    padding-left:1.5rem;
                }
            }
        }

        /*
        ul
        {

        }
        svg,
        .numbered_index
        {
            color:$tertiary;
            font-size: 22px;
            font-weight: 700;

            width:22px;
            margin-right:12px;
        }
        .numbered_index:after
        {
            content:".";
        }
        li
        {
            display:flex;
            margin-bottom:$grid-gutter-width/2;
            span
            {
                font-size:16px;
            }
            .numbered_item
            {
                flex:1;
                width:calc(100% - 42px);
            }
        }
        */
    }
</style>
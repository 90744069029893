<template>
    <div id="page">


        <img src="./../../../images/hero-splash-2.jpeg" id="about">

        <b-container>

            <section>
                <h1 class="text-center">Chi siamo</h1>
                <p>
                    <b>
                        La nostra società è leader in Italia nella fornitura di soluzioni business di online travel booking. Le nostre soluzioni vengono utilizzate da alcune tra le più innovative aziende italiane nel welfare aziendale, nel marketing, nei piani loyalty e nelle incentivazioni aziendali al trade.
                    </b>

                  <br/><br/>
                  Ci avvaliamo di avanzate tecnologie IT e di collaborazioni con i leader globali del travel online ma manteniamo anche una caratterizzazione territoriale. Infatti, tutto il nostro staff, dallo sviluppo tecnologico all'assistenza clienti passando per lo sviluppo strategico e commerciale, si trova nella nostra sede in provincia di Rimini.
                </p>
            </section>

            <section>
              <Quote :text="'Il nostro team work è multi-disciplinare annoverando competenze\n'+
                'nell\'ambito viaggi e online travel, incentive&trade, gift card, cofanetti\n'+
                'regalo e naturalmente information technology'" />
            </section>

          <section>

            <b-row>
              <b-col sm="12" lg="7">
                <h4 class="text-center">La nostra mission</h4>

                <p>
                    La nostra mission è quella di realizzare soluzioni di travel booking fortemente orientate al cliente.
                    <br>
                    Sviluppo tecnologico e assistenza clienti generano sicurezza e tranquillità per gli utenti che viaggiano prenotando con {{site.brand}} ma anche per le aziende che affidano i loro dipendenti e i loro clienti ai nostri servizi.
                </p>

              </b-col>
              <b-col sm="12" lg="5">
                <RoundedImage
                  :image="require('./../../../../public/assets/customer-care.jpg')"
                  :title="''"
                  :text="''"
                />
              </b-col>
            </b-row>

          </section>

          <section>
              <p>Per farlo usiamo un attento mix di tecnologia e competenze umane:</p>
              <Checklist :items="checklistItems_mission" />
          </section>

          <div class="mt-4 mb-4 d-flex justify-content-center">
            <router-link :to="{name: 'homepage'}">
              <BtnBack text="Torna alla Home" />
            </router-link>
          </div>

        </b-container>

        <b-container>

          <section>

            <h1 id="come-funziona" class="text-center">Come funziona</h1>

            <!--<h4 class="text-center">Le cose fondamentali da capire sono:</h4>-->

            <NumberedItems :items="numberedItems_comeFunziona" />

            <br/>

            <h4 id="cosa-in-piu" class="text-center">Cosa ha di più {{site.brand}} rispetto ad un normale sito di prenotazioni?</h4>

            <!--<Checklist :items="checklistItems_cosaVadobay" :numbers="true" />-->

            <div class="checklist">
                <ul>
                    <li>
                        <span class="checklist_icon">
                            <SvgSemplicitaSmall />
                        </span>
                        <span class="checklist_item">
                            <b>La semplicità di utilizzo del sito</b>
                            <br>
                            <!--Il nostro sito è <b>integrato</b> con i principali portali di welfare aziendale e permette ai dipendenti delle aziende servite di utilizzare il loro credito welfare per prenotare viaggi in maniera semplice e sicura. L'utente che accede a {{site.brand}} dal portale welfare della sua azienda “trascina” il suo credito welfare e lo può utilizzare per pagare la sua prenotazione con un <b>addebito in tempo reale sul suo conto di welfare aziendale</b>-->
                              Il nostro sito è integrato con portali di welfare aziendale o con specifiche piattaforme di loyalty e permette agli utenti di <b>utilizzare il loro credito virtuale per prenotare viaggi</b> in maniera semplice e sicura. L'utente che accede a {{ this.appConfig().site.brand }} da un sito partner porta con sè il proprio credito e lo può utilizzare per pagare la sua prenotazione con <b>un addebito in tempo reale sul suo wallet virtuale.</b>
                        </span>
                    </li>
                    <li>
                        <span class="checklist_icon">
                            <SvgWhitelistSmall />
                        </span>
                        <span class="checklist_item">
                            <b>L'attenta selezione delle strutture proposte</b>
                            <br>
                            {{site.brand}} seleziona in modo attento gli hotel e le altre strutture di ospitalità che ti proponiamo. Infatti delle oltre 500.000 strutture a cui abbiamo accesso ne proponiamo solo circa la metà. La chiamiamo <b>White List</b> perchè il meccanismo opera in modo preventivo bloccando il display delle nuove strutture disponibili finché queste non abbiano raggiunto un livello reputazionale sufficiente
                        </span>
                    </li>
                    <li>
                        <span class="checklist_icon">
                            <SvgDoubleCheckSmall />
                        </span>
                        <span class="checklist_item">
                            <b>La cura che poniamo ad ogni singola prenotazione</b>
                            <br>
                            Ogni prenotazione effettuata sul nostro sito, va incontro ad una procedura di <b>Double Check</b> ovvero una seconda verifica che avviene dopo la conferma della prenotazione verificando direttamente con la struttura, alcuni giorni prima del check-in, che non ci siano problemi e che tutto sia confermato come da prenotazione già confermata.
                        </span>
                    </li>
                </ul>
            </div>

            <br/>
            <br/>

            <b-row>
              <b-col sm="12" lg="7">
                <p>
                  {{site.brand}} si integra perfettamente con le meccaniche web di promozioni e concorsi B2B e B2C.
                  <br><br>
                  {{site.brand}} permette alle aziende di inserire i viaggi in soluzioni di loyalty realmente innovative perché garantiscono al cliente una online User Experience all'altezza degli standard attesi anche nello speciale e complesso ambito del travel.
                </p>
              </b-col>
              <b-col sm="12" lg="5">
                <RoundedImage
                  :image="require('./../../../../public/assets/come-funziona.jpg')"
                  :title="''"
                  :text="''"
                />
              </b-col>
            </b-row>
          </section>

          <div class="mt-4 mb-4 d-flex justify-content-center">
            <router-link :to="{name: 'homepage'}">
              <BtnBack text="Torna alla Home" />
            </router-link>
          </div>

          <section v-if="mixinUseTicketCompliments() && !mixinIsAuthenticated()">
            <h4 id="etc" class="text-center gold uppercase">Come prenotare usando i Ticket Compliments &reg;</h4>
            <p>
              Con {{site.brand}} è possibile <b>prenotare un soggiorno utilizzando i tuoi Ticket compliments &reg;</b>. Sarà necessario effettuare il login utilizzando le tue credenziali di accesso al tuo portale beneficiari o l’app Ticket Compliments &reg;. Clicca il seguente bottone per accedere al servizio.
            </p>
            <br><br>
            <p class="text-center">
              <a :href="this.$config.endpoints.etcSso" @click="trackEtcSso()">
                <SvgEdenredButtonBlue />
              </a>
            </p>
          </section>

          <div class="mt-4 mb-4 d-flex justify-content-center" v-if="mixinUseTicketCompliments() && !mixinIsAuthenticated()">
            <router-link :to="{name: 'homepage'}">
              <BtnBack text="Torna alla Home" />
            </router-link>
          </div>

          <section>
            <h1 id="assistenza" class="text-center">Assistenza</h1>
            <p>
              La cura che mettiamo nel supportare ogni nostro utente e nel
              monitorare ogni prenotazione fatta su {{site.brand}} è una delle cose che ci
              contraddistingue.
              La nostra è un'assistenza multicanale curata da uno staff interno
              dislocato nei nostri uffici con operatori che potrai chiamare per nome.
            </p>

            <p>
              Ecco qui di seguito tutti i canali attraverso i quali puoi contattarci:
            </p>
          </section>

        </b-container>


        <b-container fluid>
          <section>
            <Prefooter />
          </section>
        </b-container>


        <b-container>

          <section>
            <Contacts />
          </section>


          <section class="text-center">
            <h2>{{site.brand}}: Soluzioni Business<br>per l’online travel booking</h2>
            <br>
            <p>
              La nostra società è leader in Italia nella fornitura di soluzioni business di online travel booking. Le nostre soluzioni vengono utilizzate da alcune tra le più innovative aziende italiane nel welfare aziendale, nel marketing, nei piani loyalty e nelle incentivazioni aziendali al trade.
            </p>
            <br>
            <b-row class="business-icons">
              <b-col sm="12" lg="4">
                <h4 class="icon-title">Loyalty</h4>
                <SvgLoyalty />
              </b-col>
              <b-col sm="12" lg="4">
                <h4 class="icon-title">Marketing</h4>
                <SvgMarketing />
              </b-col>
              <b-col sm="12" lg="4">
                <h4 class="icon-title">Welfare aziendale</h4>
                <SvgWelfare />
              </b-col>
            </b-row>
          </section>

          <section>
            <p>
              Le nostre soluzioni dedicate ti permetteranno di integrare davvero il
              travel booking online all'interno di concorsi a premi, campagne
              promozionali, incentive al trade e piani loyalty.
            </p>
            <p>
              Con {{site.brand}} potrai davvero fornire ai tuoi clienti, partner e
              dipendenti la possibilità di prenotare viaggi all'interno delle tue
              campagne e soprattutto potrai farglielo fare online in totale sicurezza e
              semplicità <b>eliminando ogni soluzione di continuità</b> tra le tue
              piattaforme tecnologiche e il nostro servizio di travel booking per una
              <b>User Experience all'altezza degli standard attesi contemporanei</b>.
            </p>
            <p>
              Parlane con un nostro consulente, scrivi a <a :href="'mailto:'+site.emailBusiness">{{site.emailBusiness}}</a>
            </p>
          </section>


        </b-container>

        <div class="mt-4 mb-4 d-flex justify-content-center">
            <router-link :to="{name: 'homepage'}">
                <BtnBack text="Torna alla Home" />
            </router-link>
        </div>

    </div>
</template>

<script>
  //import Vue from 'vue'
  //import SearchForm from '../../blocks/search-form/search-form'
  import SvgSemplicitaSmall from '../../svg/semplicita-small'
  import SvgWhitelistSmall from '../../svg/whitelist-small'
  import SvgDoubleCheckSmall from '../../svg/double-check-small'
  import SvgEdenredButtonBlue from '../../svg/edenred-button-blue'
  import Quote from '../../blocks/common/quote'
  import BtnBack from '../../atoms/btn-back'
  import Checklist from '../../blocks/common/checklist'
  import NumberedItems from '../../blocks/common/numbered-items'
  //import CustomerCare from '../../blocks/common/customer-care'
  import Contacts from '../../blocks/common/contacts'
  import RoundedImage from '../../blocks/common/rounded-image'
  import SvgLoyalty from '../../svg/loyalty'
  import SvgMarketing from '../../svg/marketing'
  import SvgWelfare from '../../svg/welfare'
  import Prefooter from '../../blocks/common/prefooter'

  export default {
    name: 'page',
    metaInfo: {
      title: 'informazioni',
    },
    components: {
        SvgSemplicitaSmall,SvgWhitelistSmall,SvgDoubleCheckSmall,
      SvgEdenredButtonBlue, Contacts, Quote, Checklist, RoundedImage, SvgLoyalty, SvgMarketing, SvgWelfare, Prefooter,
      NumberedItems, BtnBack
    },
    data() {
        return {
          site: this.appConfig().site,
          checklistItems_mission : {
            0: "Il continuo sforzo di sviluppo tecnologico degli algoritmi che\n" +
              "selezionano i migliori tra gli oltre 500.000 hotel disponibili a cui\n" +
              "accediamo a livello italiano e internazionale, come anche lo sviluppo\n" +
              "di innovativi meccanismi di double check delle prenotazioni, non\n" +
              "sarebbero nulla senza la gentilezza, la competenza e la disponibilità\n" +
              "del nostro staff di customer care",
            1: "L'incessante lavoro di miglioramento\n" +
              "della user experience del nostro sito web non sarebbe nulla senza la\n" +
              "profonda comprensione, da parte del nostro management, dell'universo\n" +
              "travel nelle sue incessanti evoluzioni e cambiamenti",
          },
          numberedItems_comeFunziona : {
            0: {
                title: "ACCEDI A " + this.appConfig().site.brand.toUpperCase() + " DAL TUO SITO DI WELFARE AZIENDALE O DAL TUO SITO LOYALTY",
                content: "<b>per utilizzare il servizio occorre accedere a " + this.appConfig().site.brand + " partendo dal link dentro al proprio portale welfare, loyalty o promozionale.</b><br>Se sei arrivato su questo sito da Google o digitando l’indirizzo " + this.appConfig().site.host + " sul browser stai visualizzando una versione che non permette di fare ricerche e prenotazioni."
              },
            1: {
                title: "COME PAGARE LA PRENOTAZIONE",
                content: "il <b>pagamento della prenotazione</b> è la cosa più semplice del mondo perchè viene fatto tramite <b>addebito diretto e automatico sul tuo wallet virtuale (welfare o punti loyalty).</b> Non è possibile pagare con carta di credito."
              },
              /*
            2: {
                title: "COME PAGARE LA PRENOTAZIONE",
                content: "una volta che sei acceduto a " + this.appConfig().site.brand + " partendo dal tuo portale welfare il <b>pagamento della prenotazione</b> è la cosa più semplice del mondo perchè viene fatto tramite <b>addebito diretto e automatico sul proprio credito welfare.</b>"
              },
              */
          },
        }
    },
    mounted() {

    },
    methods:{

    }
  }
</script>

<style lang="scss">
    
    
    

    .checklist_icon
    {
        width:45px;
        margin-right: 1rem;
        display: flex;
        align-items:flex-start;
        justify-content:flex-start;
        svg
        {
            min-width: 100%;
            height:auto;
        }
    }
</style>
<template>
    <div id="page">

        <br/>

        <b-container>

            <div class="text-right">
              <p class="d-inline" v-b-popover.hover.top="'Le informazioni su questa pagina sono state verificate il ' + lastUpdateDate">Ultimo aggiornamento {{lastUpdateDate}}</p>
            </div>

            <section>

                <h1 class="text-center">Emergenza COVID-19</h1>

                <!--<h4 class="text-center">Scegli la cancellazione gratuita</h4>-->

                <br>

                <p>
                  Monitoriamo costantemente l'evoluzione delle normative anti-Covid19 e gestiamo di conseguenza le prenotazioni e le modalità di cancellazione e rimborso dovute a causa di forza maggiore come la implementazione di zone con limitazioni di movimento.
                </p>

                <div class="numbered">
                    <ul>
                        <li>
                            <div class="heading">
                                <!--<span class="numbered_index">1.</span>-->
                                <span class="numbered_item">Informazione e trasparenza: le misure dei singoli hotel</span>
                            </div>
                            <span class="numbered_item">
                              Sulla pagina di ognuna delle {{config.guiSettings.totalPropertiesCount}} strutture che ti proponiamo sul nostro sito trovi informazione chiare ed in evidenza sulle misure di sicurezza e sui protocolli sanitari e igienici implementati.
                            </span>
                        </li>

                        <li>
                          <div class="heading">
                            <!--<span class="numbered_index">2.</span>-->
                            <span class="numbered_item">Informazione e trasparenza: le normative governative aggiornate</span>
                          </div>
                          <span class="numbered_item">
                            Nelle pagine delle singole strutture trovi anche dei link di riferimento alle pagine web governative con gli aggiornamenti sulle normative e le modalità di viaggio verso l'Italia e l'estero.
                          </span>
                        </li>

                        <li>
                          <div class="heading">
                            <!--<span class="numbered_index">3.</span>-->
                            <span class="numbered_item">Scegli la cancellazione gratuita</span>
                          </div>

                          <span class="numbered_item">
                            Ti consigliamo la scelta di soluzioni con Cancellazione gratuita. Su {{config.site.brand}} trovi sia soluzioni con Cancellazione gratuita sia soluzioni Non rimborsabili.
                          </span>
                          <br/><br/>
                          <span class="numbered_item">
                            Se scegli soluzioni con Cancellazione gratuita potrai cancellare la prenotazione (alle condizioni e nei termini indicati) e ottenere la generazione di un Buono sconto di pari valore per fare una nuova prenotazione.
                          </span>
                          <br/><br/>
                          <span class="numbered_item">
                            Se scegli una soluzione Non rimborsabile non potrai ottenere il rimborso (Buono sconto) in caso tu debba cancellarla. Naturalmente, su tua richiesta, il nostro staff sarà felice di contattare la struttura per verificare la possibilità di un rimborso ma la concessione di questa possibilità sarà a totale discrezione della struttura.
                          </span>
                        </li>

                    </ul>
                </div>

            </section>


        </b-container>


    </div>
</template>

<script>

  import Vue from "vue";

  export default {
    name: 'covid19',
    metaInfo: {
      title: 'covid-19',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Rimborsabilità e informazioni. Ecco cosa facciamo per la tua sicurezza' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Rimborsabilità e informazioni. Ecco cosa facciamo per la tua sicurezza' },
      ],
    },
    computed: {
      lastUpdateDate: function(){

        // ritorna la data di giovedì scorso o lunedì scorso (quella più vicina)

        let dayManualEdit = new Date('2021-08-27') // verrà usata questa data o il luned'/giovedì più recente
        let today = new Date()
        let todayNumber = today.getDay()


        if(todayNumber >= 4 || todayNumber == 0) {
          // tra giovedì e domenica
          if(todayNumber == 0) todayNumber++;
          today.setDate(today.getDate() - Math.abs(todayNumber - 4)) // giovedì scorso
        }else{
          // tra lunedì e mercoledì
          today.setDate( today.getDate() - (todayNumber - 1) ) // lunedì scorso
        }

        if(dayManualEdit > today) today = dayManualEdit;

        return ('0' + today.getDate()).slice(-2) + "-"
            + ('0' + (today.getMonth()+1)).slice(-2)  + "-"
             + today.getFullYear()
      },
    },
    data(){
      return{
        config: Vue.prototype.$config,
      }
    }
  }
</script>

<style lang="scss">
    
    
    

    .numbered {
        margin-top: 3rem;
        margin-bottom: 2rem;

        li {
            margin-bottom: 1rem;
        }

        .heading {
            display: flex;
            align-items: center;
            color: $tertiary;
            font-weight: 900;
            font-size: 18px;
            margin-bottom: 12px;
            min-height: 60px;
            line-height: 120%;
        }

        .numbered_index {
            font-weight: 900;
            font-size: 24px;
            color: $white;
            background: $tertiary;
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 8px;
            text-align: center;
            line-height: 100%;
            padding-top: 6px;
            padding-left: 4px;
        }

        @include media-breakpoint-up(lg) {
            ul {
                display: flex;
                flex-wrap:wrap;
            }
            li {
                flex: 1;
                width:100%;
                min-width: 100%;
                padding-left:0 !important;

                margin-top:20px;

                &:nth-child(1) {
                    padding-right: 1.5rem;
                }

                &:nth-child(2) {
                    padding-left: 1.5rem;
                }
            }
        }

    }
</style>
<template>
    <div id="page-error">
        <b-container>
            <b-row>
                <b-col lg="8" offset-lg="2">
                    <h4 class="secondary">C'è stato un problema</h4>
                    <br>
                    <p v-html="error"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
export default {
    name: 'error',
    metaInfo(){
      return {
        title: 'error',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: '404' },
          { vmid: 'robots', name: 'robots', content: 'noindex' },
        ]
      }
    },
    data()
    {
        return {
            error: Base64.decode(this.$route.query.error)
        }
    }
}
</script>

<style lang="scss">
    
    #page-error {
      padding-top: 50px;
      margin-bottom:6rem;

      @include media-breakpoint-up(lg) {
        padding-top: 250px;
        margin-bottom:8rem;
      }
    }
</style>
<template>
    <div class="newsletter-page">
        <b-container>
            <b-row>
                <b-col lg="8" offset-lg="2">
                    <h2 class="newsletter-page--title">Info</h2>
                    <br>
                    <p v-html="info"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
export default {
    name: 'info',
    metaInfo(){
      return {
        title: 'info',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: '404' },
          { vmid: 'robots', name: 'robots', content: 'noindex' },
        ]
      }
    },
    data()
    {
        return {
            info: Base64.decode(this.$route.query.info)
        }
    }
}
</script>

<style lang="scss">
    .newsletter-page
    {
        padding-top:$grid-gutter-width;
        margin-bottom:6rem;
        text-align: center;
        @include media-breakpoint-up(lg)
        {
            padding-top:0;
            margin-top:$page-header-desktop-height;
            margin-bottom:8rem;
        }
        &--title
        {
            @include customize-color("secondary");
            margin-top:0;
        }
    }
</style>
<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="jpToken == '' || jpToken == undefined">
            C'è stato un problema, token non trovato!
        </div>
        
        <div v-else>

            <span v-if="this.$router.currentRoute.query.status =='PLACED'">
              Attendi, stiamo effettuando la prenotazione richiesta...
            </span>

            <span v-else>
              Attendi, stiamo processando la tua richiesta...
            </span>

            <br><br>
            <Spinner v-if="loading" />
        </div>
        <br><br>
    </b-container>
</template>

<script>
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'
import Vue from "vue";
export default {
    name: 'jp-checkout',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            jpToken: '',
            loading: true,
            timing: 5000,
            counter: 0,
        }
    },
    mounted()
    {
        let _this = this

        _this.jpToken = _this.$router.currentRoute.query.jpToken

        if(_this.jpToken !== undefined)
        {
            _this.jpCheckToken()
        }
        else
        {
            _this.loading = false
        }
    },
    methods:
    {
        jpCheckToken()
        {
            let _this = this 

            setTimeout(function(){

                _this.counter = _this.counter + 1
                //window.console.log('counter: '+ _this.counter)
                
                Api.jpCheckToken({
                    jpToken: _this.jpToken,
                    counter: _this.counter
                })
                .then((results) => {

                    if(results.tryAgain) _this.jpCheckToken()

                    if(results.check)
                    {

                        if(_this.$gtm){

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedCheckout',
                            label: Vue.prototype.$config.integration.email,
                            value: results.price,
                          });

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedCheckout',
                            label: Vue.prototype.$config.integration.company,
                            value: results.price,
                          });


                          let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                              ? _this.geo.inSalabamId
                              : (_this.geo.matchLevel == 'property')
                                  ? _this.geo.propertyId
                                  : 0;

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'confirmedCheckoutSearch',
                            action: _this.geo.matchLevel,
                            label: _this.geo.label,
                            value: eventGeoExtraValue,
                          });

                        }

                        if(window.LogRocket) window.LogRocket.track('confirmedCheckout')
                        if(window.clarity) window.clarity("set", "checkout", "confirmed")

                        if(results.data.updateAvailabilityTo) _this.mixinSendMutation('setAvailability',results.data.updateAvailabilityTo)

                        _this.mixinGoTo('confirm',{
                            confirmcode: results.data.confirmcode,
                            itinerary_id: results.data.itinerary_id,
                            msg: results.data.msg,
                            newAffiliateReferenceId: results.data.newAffiliateReferenceId,
                        })

                    } // if results.check



                })
            }, _this.timing)
        }
    },
    computed: {
      geo: {
        get() { return this.$store.state.geo },
        set() {}
      },
    },
}
</script>
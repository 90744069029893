<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="data == '' || data == undefined">
            C'è stato un problema, devi accedere dal portale Jointly
        </div>
        
        <div v-else>
            Accesso in corso...
            <br><br>
            <Spinner v-if="loading" />
            <!--
            <div v-else>
                <p>jpToken: {{jpToken}}</p>
            </div>
            -->
        </div>
        <br><br>
    </b-container>
</template>

<script>
import Vue from 'vue'
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'

export default {
    name: 'jp-sso',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            data: '',
            loading: true,
        }
    },
    mounted()
    {
        let _this = this

        _this.data = _this.$router.currentRoute.query.data
        _this.jpSso()

    },
    methods:
    {
        jpSso()
        {
            let _this = this 

            Api.jpSso({
                app: Vue.prototype.$config.app,
                data: _this.data,
            })
            .then((results) => {

              window.location.href = '/?token='+results.token

            })

        }
    },
}
</script>
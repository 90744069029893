<template>
    <div id="page">

        <div class="page-search-form">
            <search-form
                v-on:submit-search="submitSearch"
                v-on:filter-changed="filterChanged"
            />
        </div>

        <img src="./../../images/hero-splash.png">

        <b-container>

            <section>
                <h1 class="text-center">Titolo H1</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
                </p>
            </section>

            <section>
              <Quote :text="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae.'" />
            </section>

            <section>
                <h2 class="text-center">Sottotitolo h2</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
                </p>
            </section>

            <section>
                <h4 class="text-center">Checklist</h4>
                <Checklist :items="checklistItems" />
            </section>

        </b-container>

        <b-container>
          <section>
            <h1 class="text-center">FAQ</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
            </p>

            <br><br>
            
            <Accordion :items="[
              {
                title: 'Domanda 1',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
              },
                        {
                title: 'Domanda 2',
                content: 'Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur.'
              },
              {
                title: 'Domanda 3',
                content: 'Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. '
              }
            ]" />
          </section>
        </b-container>
        
        <section>
          <CustomerCare />
        </section>
        
        <b-container>
          <section>
            <Contacts />
          </section>

          <section>
              <h4 id="sottotitolo-h4" class="text-center">Sottotitolo h4</h4>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
              </p>
              <br>
              <p class="text-2-col">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
              </p>
          </section>

          <section>
              <h4 class="text-center">Sottotitolo h4</h4>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. 
              </p>
              
              <br><br>
              
              <b-row>
                <b-col sm="12" lg="7">
                  <Checklist :items="checklistItems" />
                </b-col>
                <b-col sm="12" lg="5">
                  <RoundedImage
                    :image="require('./../../../public/assets/customer-care.jpg')"
                    :title="''"
                    :text="''"
                  />
                </b-col>
              </b-row>
              
          </section>

          <section>
            <b-row>
              <b-col sm="12" lg="4">
                <RoundedImage
                  :image="require('./../../../public/assets/customer-care.jpg')"
                  :title="'1. Lorem ipsum'"
                  :text="'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'"
                />
              </b-col>
              <b-col sm="12" lg="4">
                <RoundedImage
                  :image="require('./../../../public/assets/customer-care.jpg')"
                  :title="'2. Lorem ipsum'"
                  :text="'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'"
                />
              </b-col>
              <b-col sm="12" lg="4">
                <RoundedImage
                  :image="require('./../../../public/assets/customer-care.jpg')"
                  :title="'3. Lorem ipsum'"
                  :text="'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'"
                />
              </b-col>                            
            </b-row>
          </section>


          <section class="text-center">
              <h2>{{site.brand}}: Soluzioni Business<br>per l’online travel booking</h2>
              <p>
                {{ site.brand }} è un sito di travel booking dedicato al welfare aziendale ma {{ site.brand }} è molto di più.
                {{ site.brand }} è in grado di rispondere alle esigenze delle aziende nell'ambito:
              </p>
              <b-row class="business-icons">
                <b-col sm="12" lg="4">
                  <h4 class="icon-title">Loyalty</h4>
                  <SvgLoyalty />
                </b-col>
                <b-col sm="12" lg="4">
                  <h4 class="icon-title">Marketing</h4>
                  <SvgMarketing />
                </b-col>
                <b-col sm="12" lg="4">
                  <h4 class="icon-title">Welfare aziendale</h4>
                  <SvgWelfare />
                </b-col>
              </b-row>
          </section>

          <section>
              <p>
                Le nostre soluzioni dedicate ti permetteranno di integrare davvero il
                travel booking online all'interno di concorsi a premi, campagne
                promozionali, incentive al trade e piani loyalty.
              </p>
              <p>
                Con {{ site.brand }} potrai davvero fornire ai tuoi clienti, partner e
                dipendenti la possibilità di prenotare viaggi all'interno delle tue
                campagne e soprattutto potrai farglielo fare online in totale sicurezza e
                semplicità <b>eliminando ogni soluzione di continuità</b> tra le tue
                piattaforme tecnologiche e il nostro servizio di travel booking per una
                User Experience all'altezza degli standard attesi contemporanei.
              </p>
              <p>                
                Parlane con un nostro consulente, scrivi a <a :href="'mailto:'+ site.emailInfo">{{site.emailInfo}}</a>
              </p>
          </section>


        </b-container>
        
    </div>
</template>

<script>
  import Vue from 'vue'
  import SearchForm from '../blocks/search-form/search-form'
  import Quote from '../blocks/common/quote'
  import Checklist from '../blocks/common/checklist'
  import CustomerCare from '../blocks/common/customer-care'
  import Contacts from '../blocks/common/contacts'
  import RoundedImage from '../blocks/common/rounded-image'
  import SvgLoyalty from '../svg/loyalty'
  import SvgMarketing from '../svg/marketing'
  import SvgWelfare from '../svg/welfare'
  import Accordion from '../blocks/common/accordion'

  export default {
    name: 'page',
    components: {
      Accordion, SearchForm, Quote, Checklist, CustomerCare, Contacts, RoundedImage,
      SvgLoyalty, SvgMarketing, SvgWelfare
    },
    data() {
        return {
          //phoneNumber: Vue.prototype.$config.site.phoneNumber,
          checklistItems : {
            0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            1: "Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur.",
            2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            3: "Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae. Est sit amet facilisis magna etiam tempor orci. Proin fermentum leo vel orci porta. Semper feugiat nibh sed pulvinar."
          },
          site: Vue.prototype.$config.site,
        }
    },
    mounted() {
      // resetto filtri e categorie eventualmente settati nello state tramite local storage
      this.mixinSendMutation('setAmenities',[])
      this.mixinSendMutation('setCategories',[])
    },
    methods:{
      filterChanged(){},
      submitSearch()
      {
        this.mixinSendMutation('setTriggerSearch',true)
        this.mixinGoTo('search',{})
      }
    }
  }
</script>

<style lang="scss">
    
    
    

    .business-icons
    {
      margin-top:$grid-gutter-width;
      .icon-title
      {
        color:$tertiary !important;
        font-size:22px;
      }
    }
</style>
<template>
    <div class="customer-care">
        <h1 class="title">Assistenza</h1>
        <b-row class="content">
            <b-col sm="12" lg="7" class="col-txt">
                <ul>
                    <li>
                        <SvgCustomerCareFaq />
                        <p>
                            <span class="highlight">LEGGI</span> LE RISPOSTE ALLE DOMANDE FREQUENTI
                        </p>
                    </li>
                    <li>
                        <SvgCustomerCareMail />
                        <p>
                            INVIACI UN’EMAIL AD <a href="mailto:">ASSISTENZA@BOOCLICK.COM</a>
                        </p>
                    </li>
                    <li>
                        <SvgCustomerCareChat />
                        <p>
                            PARLA IN <a>CHAT</a> CON UN NOSTRO ASSISTENTE
                        </p>
                    </li>
                    <li>
                        <SvgCustomerCareWhatsapp />
                        <p>
                            SCRIVICI SU WHATSAPP A QUESTO NUMERO <a>379 141 50 10</a>
                        </p>
                    </li>
                    <li>
                        <SvgCustomerCarePhone />
                        <p>
                            ASSISTENZA DAL LUNEDI AL VENERDI DALLE 9 ALLE 19
                            CHIAMACI AL <a>0541 1646667</a>
                        </p>
                    </li>
                </ul>
            </b-col>
            <b-col sm="12" lg="5" class="col-img" :style="{ backgroundImage: 'url('+image+')' }">
                <img :src="image">
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SvgCustomerCareFaq from '@/components/svg/customer-care-faq'
import SvgCustomerCareMail from '@/components/svg/customer-care-mail'
import SvgCustomerCareChat from '@/components/svg/customer-care-chat'
import SvgCustomerCareWhatsapp from '@/components/svg/customer-care-whatsapp'
import SvgCustomerCarePhone from '@/components/svg/customer-care-phone'
export default {
    name: 'quote',
    components:
    {
        SvgCustomerCareFaq,
        SvgCustomerCareMail,
        SvgCustomerCareChat,
        SvgCustomerCareWhatsapp,
        SvgCustomerCarePhone    
    },
    props:
    {
    },
    computed:{
        image() {
            return require('./../../../../public/assets/customer-care.jpg')
        }
    }
}
</script>

<style lang="scss">
    
    
    
    

    .customer-care
    { 
        font-weight:400;
        .title
        {
            text-align:center;
        }
        .content
        {
            background:$white;
        }
        .col-img
        {
            background-size:cover;
            background-position:center center;
            min-height:320px;
            img {
                width:100%;
                visibility:hidden
            }
        }
        .col-txt
        {
            display:flex;
            flex-direction: column;
            justify-content: center;
            padding:$grid-gutter-width;
        }
        ul
        {
            margin:0;
            padding:0;
            li
            {
                display:flex;
                align-items: center;
                margin-bottom:1rem;
            }
            svg
            {
                width:32px;
            }
            p {
                padding-left:1.5rem;
                margin-bottom:0;
                text-transform: uppercase;
                font-weight: 500 !important;
                width:calc(100% - 2rem)
            }
            a,.highlight
            {
                @include customize-color("secondary");
            }
            @include media-breakpoint-up(lg)
            {
                //padding-left:$grid-gutter-width;
                max-width:700px;
                li
                {
                    margin-bottom:2rem;
                }
                svg
                {
                    width:52px;
                }
                p {
                    padding-left:2rem;
                    font-size:17px !important;
                    width:calc(100% - 3rem)
                }
            }
        }
        @include media-breakpoint-up(lg)
        {
            .col-img {order:1}
            .col-txt {order:2}
        }
    }
</style>
<template>
    <div class="rounded-image">
        <span class="title" v-if="title">{{title}}</span>
        <div class="image" :style="{ backgroundImage: 'url('+image+')' }"></div>
        <span class="text" v-if="text">{{text}}</span>
    </div>
</template>

<script>
export default {
    name: 'rounded-image',
    components:
    {
    },
    props:
    {
        title: String,
        image: String,
        text: String
    }
}
</script>

<style lang="scss">
    
    
    

    .rounded-image
    {
        text-align: center;
        .title
        {
            color:$tertiary;
            font-weight: 600;
            margin-bottom:1rem;
            display: block;
        }
        .image
        {
            width: 240px;
            height: 240px;
            margin:auto;
            border-radius: 50%;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            margin-bottom:1rem;
            display: block;
        }
        .text
        {
            font-weight:400;
            font-size: 14px;
        }
    }
</style>
<template>
    <div class="user-content">
        <b-container>
            <h1 class="user-content__title">
                <strong>Preferiti e cronologia -</strong>&nbsp;<span class="user-content__title-small">La pagina dedicata ai tuoi preferiti e alle strutture già viste</span>
            </h1>
        </b-container>

        <section>
            <b-container>
                <FavoritesSlider />
            </b-container>
        </section>

        <section>
            <b-container>
                <HistorySlider />
            </b-container>
        </section>
    </div>
</template>

<script>
//import Vue from 'vue'
import FavoritesSlider from '@/components/blocks/user/favorites-slider'
import HistorySlider from '@/components/blocks/user/history-slider'

export default
{
    name: 'user',
    props:
    {
    },
    components:
    {
        FavoritesSlider,
        HistorySlider,
    },
    data()
    {
        return {
        }
    },
    mounted()
    {
    }
}
</script>

<style lang="scss">
    
.user-content
    {
        padding-top: 50px;

        @include media-breakpoint-up(lg) {
            padding-top: 250px;
        }
        
        &__title
        {
            color:$black;
            @include customize-color("dark");
            font-size: 20px;
            font-weight: normal;
            @include media-breakpoint-up(md)
            {
                font-size:35px;
            }
            &-small
            {
                font-size: 80%;
            }
        }
    }
</style>
<template>
  <div class="paymentslink">
    <b-container>
      <div v-if="payment_get.status === 'NEW'">
         <b-row v-if="!completed && !purchasing">
          <b-col cols="12" class="text-center">
            <h2 class="mb-4 pb-4">Effettua il pagamento</h2>
          </b-col>
          <b-col md="4" offset-md="1">
            <p><strong>Tipo di servizio:</strong> {{payment_get.productName}}</p>
            <p><strong>Descrizione del servizio:</strong> {{payment_get.description}}</p>
            <p class="paymentslink__price mt-4">Importo: € {{payment_get.live_price}}</p>
            <p class="mt-4">
              <strong class="d-block mb-2">Come effettuare il pagamento</strong>
              Compila il modulo che trovi in questa pagina. Una volta compilato correttamente in tutti i suoi campi
              ti verrà mostrato un modulo aggiuntivo in cui inserire i dati di carta di credito
            </p>
            <div class="paymentslink__cards">
              <img class="visa" src="/assets/visa.svg" alt="visa" />
              <img class="mastercard" src="/assets/mastercard.svg" alt="mastercard" />
              <img class="american-express" src="/assets/american-express.svg" alt="american-express" />
            </div>
            <div v-html="copy_security" />
          </b-col>
          <b-col md="5" offset-md="1">
            <h5 v-html="'Dati di fatturazione'" />
            <b-form-group :state="validateFields()">
              <b-row>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.firstName"
                      ref="firstName"
                      :required="true"
                      placeholder="Nome"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.lastName"
                      ref="lastName"
                      :required="true"
                      placeholder="Cognome"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.fiscalCode"
                      ref="fiscalCode"
                      :required="true"
                      placeholder="Codice Fiscale"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.phone"
                      ref="phone"
                      :required="true"
                      placeholder="Telefono"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.address"
                      ref="address"
                      :required="true"
                      placeholder="Indirizzo"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.city"
                      ref="city"
                      :required="true"
                      placeholder="Città"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.province"
                      ref="province"
                      :required="true"
                      placeholder="Provincia"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.postalCode"
                      ref="postalCode"
                      :required="true"
                      placeholder="CAP"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.email"
                      ref="email"
                      :required="true"
                      placeholder="Email"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" sm="6" class="mb-3">
                  <b-form-input
                      v-model="fields.emailConfirm"
                      ref="emailConfirm"
                      :required="true"
                      placeholder="Conferma email"
                      type="text"
                  />
                </b-col>
                <b-col cols="12" class="d-flex">
                  <b-form-checkbox ref="terms" required v-model="checkboxTerms" type="checkbox" name="checkbox" id="checkboxTerms" />
                  <label for="checkboxTerms">
                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                      <span class="secondary">Accetto le condizioni d'uso</span>
                    </router-link>
                  </label>
                </b-col>
                <b-col cols="12" class="d-flex">
                  <b-form-checkbox ref="privacy" required v-model="checkboxPrivacy" type="checkbox" name="checkbox" id="checkboxPrivacy" />
                  <label for="checkboxPrivacy">
                    <router-link :to="{name: 'privacy-policy'}" target="_blank" class="nounderline">
                      <span class="secondary">Accetto le condizioni di Privacy</span>
                    </router-link>
                  </label>
                </b-col>
              </b-row>

              <div v-if="validateFields()" class="mt-4">
                <Stripe
                  ref="stripe"
                  :amount="Number(payment_get.live_price)"
                  v-on:stripe-change="stripeChange"
                  v-on:stripe-confirm="stripeConfirm"
                />
                <b-button class="btn btn-block btn-success" @click="$refs.stripe.getIntentClientSecret()">Continua</b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col cols="12" v-if="purchasing" class="text-center">
            <Spinner />
          </b-col>
          <b-col md="6" offset-md="3" v-else>
            <h2 class="vb-heading mb-4 pb-4" v-if="checkout_message">{{checkout_message}}</h2>
            <p><strong>Tipo di servizio:</strong> {{payment_get.productName}}</p>
            <p><strong>Descrizione del servizio:</strong> {{payment_get.description}}</p>
            <p class="paymentslink__price mt-4">Importo: € {{payment_get.live_price}}</p>
            <div v-html="copy_security" />
          </b-col>
        </b-row>
      </div>

      <!-- STATUS: COMPLETE -->
      <b-row v-if="payment_get.status === 'COMPLETE'">
        <b-col md="6" offset-md="3">
          <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">
            Questa pagina di pagamento non è più attiva perchè il pagamento è stato già completato
          </h2>
          <p>
            <strong class="d-block mb-2">Perchè non posso effettuare il pagamento?</strong>
            Molto probabilmente hai già effettuato il pagamento.
          </p>
          <p class="mt-4">
            <strong class="d-block mb-2">Che cosa posso fare?</strong>
            Controlla la tua posta elettronica e verifica se hai ricevuto la mail di conferma del pagamento.
            Diversamente contatta la nostra assistenza ai riferimenti che trovi in questa stessa pagina.
          </p>
          <div v-html="copy_security" />
        </b-col>
      </b-row>

      <!-- STATUS: EXPIRED -->
      <b-row v-if="payment_get.status === 'EXPIRED'">
        <b-col md="6" offset-md="3">
          <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">Questa pagina di pagamento non è più attiva</h2>
          <p>
            <strong class="d-block mb-2">Perchè non posso effettuare il pagamento?</strong>
            Il pagamento del servizio aveva una scadenza temporale legata a disponibilità o altre condizioni:
            il termine è stato probabilmente superato.
          </p>
          <p class="mt-4">
            <strong class="d-block mb-2">Che cosa posso fare?</strong>
            Contatta la nostra assistenza ai contatti che trovi in questa stessa pagina.
          </p>
          <div v-html="copy_security" />
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
/*import Vue from 'vue'*/
import Api from '../../../api'
import Stripe from '/src/components/blocks/stripe.vue'
import Spinner from '/src/components/atoms/spinner.vue'

export default {
  name: 'payments-link',

  components: {
    Stripe,
    Spinner,
  },

  props: {
  },

  data() {
    return {
      endpoint: this.$config.endpoints.paymentsLink,

      fields: {
        firstName: '',
        lastName: '',
        fiscalCode: '',
        phone: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        email: '',
        emailConfirm: '',
      },
      checkboxTerms: false,
      checkboxPrivacy: false,

      stripeInfoCheck: false,
      stripe_completed: false,
      purchasing: false,
      completed: false,
      checkout_message: '',

      pay_code: this.$route.params.pay_code,
      payment_link_id: this.$route.params.payment_link_id,
      payment_get: {},

      copy_security: `<hr class=my-4><p><strong class="d-block mb-2">Sicurezza e protezione dei tuoi dati</strong> Sappiamo quanto sia importante per te la sicurezza durante le transazioni online. Per questo motivo, utilizziamo i più avanzati standard di sicurezza per garantire che i tuoi dati personali e di pagamento siano sempre protetti.<ul class="text--sm"><li class=mb-3>Crittografia SSL (Secure Socket Layer): Tutti i dati sensibili inseriti in questa pagina, compresi i dettagli della tua carta di credito, sono criptati attraverso la tecnologia SSL, proteggendoli da accessi non autorizzati.<li class=mb-3>Certificazioni PCI DSS (Payment Card Industry Data Security Standard): Il nostro sistema di pagamento rispetta i rigorosi standard PCI DSS, assicurando che i dati della tua carta vengano gestiti e archiviati in modo sicuro.<li>Autenticazione 3D Secure: Quando applicabile, utilizziamo il sistema 3D Secure (ad esempio, "Verified by Visa" o "Mastercard SecureCode") per offrirti un ulteriore livello di protezione durante le transazioni.</ul><p>Puoi effettuare il pagamento con la massima tranquillità, sapendo che i tuoi dati sono protetti e che ci impegniamo a garantire la tua sicurezza online.<p>Per ulteriori informazioni sulla nostra politica di sicurezza contatta il nostro team di supporto.`
    }
  },

  computed: {
    payload() {
      return {
        token: this.$config.token,
        bookingData: {
          live_price: this.payment_get.live_price,
          payments: {
            /*
            fake: {
              amount: 0
            },*/
            cc: {
              amount: this.payment_get.live_price,
            }
          }
        },
        guestFirstName: this.fields.firstName,
        guestLastName: this.fields.lastName,
        guestEmail: this.fields.email,
        guestEmailConf: this.fields.emailConfirm,
        guestPhone: this.fields.phone,
        guestFiscalCode: this.fields.fiscalCode,
        guestAddress: this.fields.address,
        guestCity: this.fields.city,
        guestPostalCode: this.fields.postalCode,
        guestProvince: this.fields.province,
        guestPrivacy: this.checkboxPrivacy,
      }
    }
  },

  mounted() {
    Api.plRequest(this.endpoint.get, {
      payCode:this.pay_code,
      paymentLinkId: this.payment_link_id
    })
    .then(
      (success) => {
        this.payment_get = success.payment
      },
    )
  },

  methods: {
    validateFields() {
      return this.mixinValidateFields([
        {
          ref: this.$refs.firstName,
          type: 'firstName',
          value: this.fields.firstName,
        },
        {
          ref: this.$refs.lastName,
          type: 'lastName',
          value: this.fields.lastName,
        },
        {
          ref: this.$refs.fiscalCode,
          type: 'fiscalCode',
          value: this.fields.fiscalCode,
        },
        {
          ref: this.$refs.phone,
          type: 'phone',
          value: this.fields.phone,
        },
        {
          ref: this.$refs.address,
          type: 'address',
          value: this.fields.address,
        },
        {
          ref: this.$refs.city,
          type: 'city',
          value: this.fields.city,
        },
        {
          ref: this.$refs.postalCode,
          type: 'postalCode',
          value: this.fields.postalCode,
        },
        {
          ref: this.$refs.email,
          type: 'email',
          value: this.fields.email,
        },
        {
          ref: this.$refs.emailConfirm,
          type: 'emailConfirm',
          value: this.fields.emailConfirm,
        },
        {
          ref: this.$refs.privacy,
          type: 'checkbox',
          value: this.checkboxPrivacy,
        },
        {
          ref: this.$refs.terms,
          type: 'checkbox',
          value: this.checkboxTerms,
        }
      ])
    },

    stripeChange(e) {
      this.stripeInfoCheck = e
    },

    stripeConfirm(e) {
      if(e.error) {
        this.stripe_completed = false
        let data = {
          msg: e.message,
          status: e.status,
          callback: function() {}
        }
        data.msg = e.result.message
        window.eventBus.$emit('gotNotification',data)

        return
      }
      this.payload.bookingData.payments.cc.paymentIntentId = e.paymentIntentId
      this.payload.bookingData.payments.cc.nameSurname = e.name
      this.payload.bookingData.payCode = this.pay_code
      this.payload.bookingData.paymentLinkId = this.payment_link_id
      this.stripe_completed = true
      this.purchasing = true

      Api.plRequest(this.endpoint.buy, this.payload)
      .then(
        (response) => {
          this.purchasing = false

          if (response.status === 500) {
            this.completed = false
          } else {
            this.completed = true
            this.checkout_message = response.msg
            window.scrollTo(0, 0)
          }
        },
        (error) => {
          this.log(error.response)
          this.checkout_message = 'C\'è stato un problema'
          this.purchasing = false
        }
      )
    },

  }
}
</script>


<style lang="scss">

  .paymentslink {
    padding: $grid-gutter-width*2 0 $grid-gutter-width 0;

    &__price {
      font-size: 32px;
      /*font-weight: $vb-font-weight-semibold;*/
      color: $primary;
    }

    &__cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      img {
        margin: 0 0.5rem;
        max-width: 40px;
        //filter: grayscale(100%);
      }
    }

    ul {
      margin-bottom: 2rem;
      padding: 0 2.5rem;
      font-size: 14px;

      li {
        list-style: disc;
      }
    }
  }

</style>
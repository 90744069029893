<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="integratedOrderId == '' || cancellaraToken == ''">
            C'è stato un problema, token non trovato!
        </div>
        
        <div v-else>

            <span v-if="loading">
              Attendi, stiamo verificando il pagamento...
            </span>

            <span v-else>
              Siamo spiacenti, si è verificato un problema.
              Contatta l'assistenza indicando il seguente codice: <b>IO-{{integratedOrderId}}</b>
            </span>

            <br><br>
            <Spinner v-if="loading" />
        </div>
        <br><br>
    </b-container>
</template>

<script>
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'
import Store from "@/vuex/store";
export default {
    name: 'satispay-confirm',
    components:
    {
        Spinner,
    },
    props:
    {
      integratedOrderId: {
        type: String,
        default: '',
      },
      cancellaraToken: {
        type: String,
        default: '',
      }
    },
    data()
    {
        return {
            loading: true,
            timing: 3000,
            counter: 0,
        }
    },
    mounted()
    {
        let _this = this

        if(this.integratedOrderId != '' && this.cancellaraToken != '')
        {
            _this.satispayConfirm()
        }
        else
        {
            _this.loading = false
        }

    },
    methods:
    {
        satispayConfirm()
        {
            let _this = this

            setTimeout(function(){

              _this.counter = _this.counter + 1

              Api.satispayConfirm({
                token: Store.state.token, // sessione
                integratedOrderId: _this.integratedOrderId,
                cancellaraToken: _this.cancellaraToken, // token sessione della prentoazione (potrebbe corrispondere ma non è imperativo)
                counter: _this.counter,
              })
              .then((results) => {

                  if(results.tryAgain) _this.satispayConfirm()

                  if(results.check)
                  {

                    if(_this.$gtm){

                      _this.$gtm.trackEvent({
                        event: 'customEvent',
                        category: 'userBehaviour',
                        action: 'confirmedCheckout',
                        label: results.email,
                        value: results.price,
                      });

                      _this.$gtm.trackEvent({
                        event: 'customEvent',
                        category: 'partnerBehaviour',
                        action: 'confirmedCheckout',
                        label: 'satispay',
                        value: results.price,
                      });


                      let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                          ? _this.geo.inSalabamId
                          : (_this.geo.matchLevel == 'property')
                              ? _this.geo.propertyId
                              : 0;

                      _this.$gtm.trackEvent({
                        event: 'customEvent',
                        category: 'confirmedCheckoutSearch',
                        action: _this.geo.matchLevel,
                        label: _this.geo.label,
                        value: eventGeoExtraValue,
                      });

                    }

                    if(window.LogRocket) window.LogRocket.track('confirmedCheckout')
                    if(window.clarity){
                      window.clarity("set", "checkout", "confirmed")
                      window.clarity("set", "satispay", "confirmedCheckout")
                    }


                    if(results.data.updateAvailabilityTo) _this.mixinSendMutation('setAvailability',results.data.updateAvailabilityTo)

                    _this.mixinGoTo('confirm',{
                      confirmcode: results.data.confirmcode,
                      itinerary_id: results.data.itinerary_id,
                      msg: results.data.msg,
                      newAffiliateReferenceId: results.data.newAffiliateReferenceId,
                    })

                  } // if results.check



              })

           }, _this.timing)

        }
    },
    computed: {
      geo: {
        get() { return this.$store.state.geo },
        set() {}
      },
    },
}
</script>
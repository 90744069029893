<template>
    <div class="accordion" role="tablist">
        <b-card v-for="(item,index) in items" v-bind:key="index" no-body>
            <b-card-header header-tag="header" role="tab">
                <b-button block href="#" v-b-toggle="accordionId + '-' + index" variant="gold">{{item.title}}</b-button>
            </b-card-header>
            <b-collapse :id="accordionId+'-'+index" accordion="accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text v-html="item.content"></b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>
<script>
export default {
    name: 'accordion',
    props:
    {
        items: Array,
        accordionId: {
          type: String,
          value: 'accordion'
        }
    }
}
</script>

<style lang="scss">
    .accordion
    {
        .card-header
        {
            .collapsed,
            .collapsed:hover,
            .collapsed:focus
            {
              //@include customize-background-color("secondary");
              background: $white;
              @include customize-color(text);
              &:hover {
                opacity: 0.8;
                @include customize-background-color(text);
                @include customize-border-color(text);
                color: $white;
              }
            }
            .not-collapsed,
            .not-collapsed:hover,
            .not-collapsed:focus
            {
                //@include customize-background-color("primary");
                @include customize-background-color(text);
                color: $white;
                &:hover {
                  opacity: 0.8;
                }
            }
            
        }
    }
</style>
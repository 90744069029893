<template>
    <div class="doublecheck">

        <Spinner v-if="loading" />

        <div v-else class="content">
            
            <b-container>
                <b-row>
                    <b-col lg="8" offset-lg="2">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab :active="info.lang == 'en'">
                                    <template #title>
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480">
                                            <path fill="#012169" d="M0 0h640v480H0z"/>
                                            <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                                            <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                                            <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                                            <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                                        </svg>
                                    </template>                                    
                                    <b-card-text>
                                        <DoublecheckCard
                                            :lang="'en'"
                                            :info="info"
                                            :strings="strings"
                                            :suggestionSent="suggestionSent"
                                            v-on:confirm="confirm"
                                            v-on:submitSuggestion="submitSuggestion"
                                        />                                        
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="IT" :active="info.lang == 'it'">
                                    <template #title>
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-it" viewBox="0 0 640 480">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                                <path fill="#fff" d="M0 0h640v480H0z"/>
                                                <path fill="#009246" d="M0 0h213.3v480H0z"/>
                                                <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
                                            </g>
                                        </svg>                                        
                                    </template>
                                    <b-card-text>
                                        <DoublecheckCard
                                            :lang="'it'"
                                            :info="info"
                                            :strings="strings"
                                            :suggestionSent="suggestionSent"
                                            v-on:confirm="confirm"
                                            v-on:submitSuggestion="submitSuggestion"
                                        />
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Api from '@/api'
    import Spinner from '@/components/atoms/spinner'
    import DoublecheckCard from './doublecheck-card.vue'
    import { VueReCaptcha } from 'vue-recaptcha-v3'

    export default {
        name: 'doublecheck',
        /*
        metaInfo(){
          return {
            title: 'conferma',
            meta: [
                { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
              ]
            }
        },*/
        components:
        {
            Spinner,
            DoublecheckCard,
        },
        props:
        {
            voucher_id: String,
            itinerary_id: String,
        },
        computed:
        {
        },
        data()
        {
            return {
                config: Vue.prototype.$config,
                recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
                recaptchaToken: '',
                loading: true,
                info: {},
                suggestion: '',
                why: '',
                link: '',
                name: '',
                checkboxPublic: false,
                suggestionSent: false,
                strings: {}
            }
        },
        methods:
        {

            validateSuggestionFields()
            {
                if (this.suggestion == '') return false
                if (this.why == '') return false
                //if (!this.checkboxPublic) return false

                return true
            },

            async confirm()
            {
                this.loading = true

                if(this.recaptchaEnabled)
                {
                    await this.$recaptchaLoaded()
                    this.recaptchaToken = await this.$recaptcha('confirm')
                }

                let _this = this
                
                Api
                    .doubleCheckConfirm({
                        recaptcha: _this.recaptchaToken,
                        voucher_id: _this.voucher_id,
                        itinerary_id: _this.itinerary_id
                    })
                    .then((data) => {
                        window.console.log(data)
                        _this.info.confirmed = true
                        _this.loading = false
                        window.scrollTo(0,0)
                    })   
            },

            async submitSuggestion(fields)
            {
                this.loading = true
                if(this.recaptchaEnabled)
                {
                    await this.$recaptchaLoaded()
                    this.recaptchaToken = await this.$recaptcha('confirm')
                }

                let _this = this
                
                Api
                    .doubleCheckSuggestion({
                        recaptcha: _this.recaptchaToken,
                        voucher_id: _this.voucher_id,
                        itinerary_id: _this.itinerary_id,
                        suggestionName: fields.suggestion,
                        suggestionWhy: fields.why,
                        suggestionLink: fields.link,
                        suggestionFromName: fields.name,
                        allowPublish: (fields.checkboxPublic ? 1 : 0)
                    })
                    .then(() => {
                        _this.info.askSuggestion = false
                        _this.suggestionSent = true,
                        _this.loading = false
                        window.scrollTo(0,0)
                    })   
            },

            setStrings()
            {
                this.strings = {
                    simple_checkin_title: {
                        it: 'Check-in senza problemi? Basta un click!',
                        en: 'A fast and simple check-in? Pls read here below!',
                    },
                    summary_title: 
                    {
                        it: 'Ecco il riepilogo della prenotazione',
                        en: 'Reservation summary',                        
                    },
                    confirm_title:
                    {
                        it: 'Grazie della collaborazione, ' + this.info.summary.property + '!',
                        en: 'Thanks for your help, ' + this.info.summary.property + '!',
                    },
                    confirm_subtitle:
                    {
                        it: 'L\'itinerario <b>' + this.info.summary.itinerary_id + '</b> è confermato e questo aiuterà ad effettuare un check-in veloce e senza problemi.',
                        en: 'The itinerary <b>' + this.info.summary.itinerary_id + '</b> is confirmed and this will help you for a fast and secure check-in.',
                    },
                    itinerary:
                    {
                        it: 'itinerario',
                        en: 'itinerary',
                    },
                    confirmation_number:
                    {
                        it: 'numero conferma',
                        en: 'confirmation number',
                    },
                    guest:
                    {
                        it: 'ospite',
                        en: 'guest',
                    },
                    guestPhone:
                    {
                      it: 'telefono ospite',
                      en: 'guest phone',
                    },
                    property:
                    {
                        it: 'struttura',
                        en: 'property',
                    },
                    room:
                    {
                        it: 'camera',
                        en: 'room',
                    },
                    adults:
                    {
                        it: 'adulti',
                        en: 'adults',
                    },
                    children:
                    {
                        it: 'bambini',
                        en: 'children',
                    },
                    check_subtitle:
                    {
                        it: 'I dati della prenotazione corrispondono alla prenotazione Expedia in tuo possesso? Puoi confermare tutto?',
                        en: 'The reservation summary is correct and everything is fine?',
                    },
                    confirm_btn:
                    {
                        it: 'Si, confermo!',
                        en: 'Yes, the booking is ok!',
                    },
                    suggestion_subtitle:
                    {
                        it: 'Lo sapevi che fornire consigli al viaggiatore prima del check-in aumenta la sua fedeltà e riduce i tassi di cancellazione?',
                        en: 'Did you know that providing pre-check-in information to travelers increases their loyalty and drastically reduces cancellation rates?',
                    },
                    suggestion_text:
                    {
                        it: 'Prenditi due minuti per dare un consiglio di viaggio a <b>' + this.info.summary.bookingName + '</b>: raccontagli in qualche riga i migliori ristoranti o le attrazioni imperdibili o più insolite della tua zona o della tua città. Gli invieremo i tuoi consigli via email insieme alla conferma che sei pronto a dargli il benvenuto nella tua struttura.',
                        en: 'Please take your time to give travelling advice to <b>' + this.info.summary.bookingName + '</b>: tell them about the best restaurants, the must-see or unusual attractions of the city. We will send this information by email to the guest, as well as the confirmation that everything is ready to welcome them.',
                    },
                    suggestion_name_title:
                    {
                        it: 'Suggerimento',
                        en: 'Suggestion',
                    },
                    suggestion_name_text:
                    {
                        it: 'nome attrazione/ristorante',
                        en: 'restaurant/place name',
                    },
                    suggestion_why_title:
                    {
                        it: 'Perché',
                        en: 'Why',
                    },
                    suggestion_why_text:
                    {
                        it: 'perchè lo consigli',
                        en: 'why du you suggest this',
                    },
                    suggestion_link_title:
                    {
                        it: 'Link',
                        en: 'Link',
                    },
                    suggestion_link_text:
                    {
                        it: 'https://www.sito.it',
                        en: 'https://www.site.com',
                    },
                    optional:
                    {
                        it: 'opzionale',
                        en: 'optional',
                    },
                    your_name_title:
                    {
                        it: 'Il tuo nome',
                        en: 'Your name',
                    },
                    your_name_text:
                    {
                        it: 'il nome di chi sta fornendo questo consiglio',
                        en: ' ',
                    },
                    allow_publish_title:
                    {
                        it: 'Pubblicazione sul nostro sito',
                        en: 'Publish on our website',
                    },
                    allow_publish_text:
                    {
                        it: 'Acconsento alla eventuale pubblicazione di questi consigli sul vostro sito',
                        en: 'I allow to publish this suggestion on this website',
                    },
                    suggestion_send_btn:
                    {
                        it: 'Invia',
                        en: 'Send',
                    },
                    suggestion_sent:
                    {
                        it: 'Il tuo suggerimento è stato inviato! Grazie.',
                        en: 'Your suggestion was sent! Thank you.',
                    },
                    text_always_visible:
                    {
                        it: 'Se invece riscontri delle discordanze o dei problemi, grazie di scriverci ad <a href="mailto:'+ this.config.site.emailSupport +'" target="_blank">'+ this.config.site.emailSupport +'</a> o di chiamarci al numero <a href="phone:'+this.config.site.phoneNumber+'">'+this.config.site.phoneNumber+'</a>',
                        en: 'If instead the reservation summary is wrong or you think that there is some possibile problem, please, explain it sending us an email to <a href="mailto:'+ this.config.site.emailSupport +'" target="_blank">'+ this.config.site.emailSupport +'</a> or call us at <a href="phone:'+this.config.site.phoneNumber+'">'+this.config.site.phoneNumber+'</a>',
                    }
                }  
            },

        },
        mounted() {

            if(this.recaptchaEnabled) Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })

            let _this = this
            Api
                .doubleCheckInfo({
                    voucher_id: _this.voucher_id,
                    itinerary_id: _this.itinerary_id
                })
                .then((info) => {
                    //window.console.log(info)
                    _this.info = info
                    _this.setStrings()
                    _this.loading = false
                })   
        }
    }
</script>

<style lang="scss">
    
    
    

    .doublecheck
    {

        .card-header-tabs
        {
            justify-content: flex-end;
            *
            {
                color:$black;
            }
        }

        .card-header .nav-item
        {
            svg
            {
                width:32px;
                height: auto;
            }
        }
    }
</style>
<template>
    <div class="publicPage landingPage landingScalapay" v-if="scalapay.enabled">
        <div class="hero">
            <img class="background" src="assets/scalapay/bg-landing.svg" />
            <div class="logo"><img src="assets/scalapay/logo-white.svg" /></div>
            <ScrollTo :selector="'#scrollTo'" />
        </div>
        <b-container class="content" id="scrollTo">
            <section>
                <h1 class="title">Prenota ora, paga poi</h1>
                <b-row class="icons">
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-1.svg" />
                        <p class="text">3 COMODE<br>RATE</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-2.svg" />
                        <p class="text">0%<br>INTERESSE</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-3.svg" />
                        <p class="text">SEMPLICE E<br>VELOCE</p>
                    </b-col>
                </b-row>
            </section>

            <section>
                <h2 class="title">Come funziona</h2>
                <b-row class="items">
                    <b-col sm="12" class="comeFunziona">
                        <div class="item">
                            <p>Scegli il tuo soggiorno su Vivamod e seleziona Scalapay al check-out come metodo di pagamento! Potrai pagare con Visa, Mastercard o Amex.</p>
                            <img src="assets/scalapay/sfondo.svg" />
                        </div>
                    </b-col>
                    <b-col sm="12" class="comeFunziona">
                        <div class="item">
                            <p>Segui il link su {{ config.site.brand }} e prosegui sul sito Scalapay per creare un'account o effettuare il pagamento. La registrazione è gratuita e l'approvazione istantanea.</p>
                            <img src="assets/scalapay/sfondo.svg" />
                        </div>
                    </b-col>
                    <b-col sm="12" class="comeFunziona">
                        <div class="item">
                            <p>Viaggia ora e dividi l’importo in 3 comode rate mensili, la prima al momento del pagamento, la seconda dopo 1 mese e l’ultima dopo 2 mesi. Senza interessi e senza busta paga!</p>
                            <img src="assets/scalapay/sfondo.svg" />
                        </div>
                    </b-col>
                </b-row>
            </section>

            <section>
                <b-row class="icons pink">
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-1.svg" />
                        <p class="text">Ricevi il link per il pagamento via email o sms</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-2.svg" />
                        <p class="text">Crea il tuo account/Login</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-3.svg" />
                        <p class="text">Paga in 3 rate, senza interessi</p>
                    </b-col>
                </b-row>
            </section>
            
            <!--<section>
                <p class="textAbove linkScalapay">
                    Visita <a href="https://www.scalapay.com" target="_blank">www.scalapay.com</a> per tutte le condizioni.
                </p>
            </section>-->
            
            <section class="text-center">
                <a @click="mixinGoTo('homepage',{})" class="btn white uppercase text-center">Scopri le disponibilità</a>
            </section>

            <br><br>

        </b-container>
    </div>
</template>

<script>
    import ScrollTo from '@/components/atoms/scroll-to'
    import Vue from "vue";
//import SvgPurchaseSpin from '../../svg/purchase-spin'
//import SvgPurchaseBed from '../../svg/purchase-bed'
//import SvgTicket from '../../svg/ticket'
//import SvgWhatsapp from '../../svg/whatsapp'

export default {
    name: 'landing-aon',
    components:
    {
        ScrollTo,
        //SvgPurchaseSpin,
        //SvgPurchaseBed,
        //SvgTicket,
        //SvgWhatsapp,
    },
    data()
    {
        return {
          config: this.appConfig(),
          scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,
        }
    },
    mounted()
    {

        if(!this.scalapay.enabled)
        {
            this.mixinGoTo('homepage', {})
            return
        }
    },
    methods:
    {
    }
}
</script>

<style lang="scss">
    
    
    

    .landingScalapay
    {
        //see _scalapay.scss
    }
</style>
<template>
    <div class="business" id="sistemi-di-pagamento">
        <div class="hero hero-drops" data-aos="fade">
            <svg class="drop-gray-1" width="439" height="531" viewBox="0 0 439 531" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M322.2 0L26 296.2C10 312.2 10 338.2 26 354.3C42 370.3 68 370.3 84.1 354.3L438.4 0H322.2Z" fill="url(#paint0_linear-svg-1)"/>
                <path d="M277.9 195.1L12 460.9C-4 476.9 -4 502.9 12 519C28 535 54 535 70.1 519L336 253.1C352 237.1 352 211.1 336 195C328 187 317.5 183 307 183C296.4 183.1 285.9 187.1 277.9 195.1Z" fill="url(#paint1_linear)"/>
                <defs>
                <linearGradient id="paint0_linear-svg-1" x1="109" y1="218" x2="615" y2="-14" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDF0F7" stop-opacity="0.75"/>
                <stop offset="1" stop-color="white"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="77.8982" y1="390.109" x2="512.043" y2="218.305" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EDF0F7" stop-opacity="0.75"/>
                <stop offset="1" stop-color="white"/>
                </linearGradient>
                </defs>
            </svg>

            <svg class="drop-gray-2" width="224" height="223" viewBox="0 0 224 223" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M223.1 111.5C223.1 173.1 173.2 223 111.6 223C49.9999 223 0.0998535 173.1 0.0998535 111.5C0.0998535 49.9 49.9999 0 111.6 0C173.2 0 223.1 49.9 223.1 111.5Z" fill="#EDF0F7"/>
            </svg>

            <div class="slider">
                <Slick ref="slick-hero-home" :options="slickOptions">
                    <div class="slide">
                        <div class="image">
                            <img class="thumb" src="assets/sistemi-di-pagamento/Vivamod-Elemento-Goccia-Viaggi-Incentive.png" />
                        </div>
                        <div class="caption">
                            <div class="text h3">
                                Per prenotare un viaggio con {{site.brand}} puoi utilizzare un'ampia serie di strumenti di pagamento:
                            </div>
                            <div class="small h1">
                                carte di credito, buoni sconto e voucher digitali
                            </div>
                        </div>
                    </div>
                </Slick>

                <!--
                <div class="slider--nav">
                    <div id="hero-slider-prev" class="arrow"><SvgChevronLeft /></div>
                    <div id="hero-slider-next" class="arrow"><SvgChevronRight /></div>
                </div>
                -->
            </div>

            <ScrollTo selector="#scroll" />
        </div>

        <b-container fluid data-aos="fade-up">
            <section class="b-cards mt-0 pt-0">
                <b-row>
                    <b-col sm="12">
                        <div class="b-slider">
                            <div class="b-slides">
                                <Slick ref="slick" :options="bSlickOptions">
                                    <div class="b-slide">
                                        <div class="content">
                                            <div class="b-card">
                                                <div class="b-card--thumb" style="background-image:url('../../../../assets/sistemi-di-pagamento/1-carte-di-credito.png')"></div>
                                                <div class="b-card--text">
                                                    <h5 class="b-card--title">Carte di credito</h5>
                                                    <div class="b-card--link mt-0">
                                                        <p class="text-left mt-3">
                                                            Accettiamo tutte le principali carte di credito, American Express, Mastercard e Visa. 
                                                            <br><br>
                                                            Ti garantiamo semplicità d'uso e soprattutto sicurezza grazie all'adozione dei protocolli e dei servizi più avanzati.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b-slide" v-if="etcEnabled">
                                        <div class="content">
                                            <div class="b-card">
                                                <div class="b-card--thumb" style="background-image:url('../../../../assets/sistemi-di-pagamento/2-ETC.png')"></div>
                                                <div class="b-card--text">
                                                    <h5 class="b-card--title">Ticket Compliments</h5>
                                                    <div class="b-card--link mt-0">
                                                        <p class="text-left mt-3">
                                                            Nella pagina di check-out, dopo avere inserito i tuoi dati, troverai un bottone con il logo Ticket Compliments. 
                                                            <br><br>
                                                            Clicca e fai login con lo username e la password che trovi sul tuo voucher digitale Ticket Compliments.
                                                            <br><br>
                                                            Potrai quindi scegliere di usare i voucher presenti nel tuo wallet, quanti usarne e pagare l'eventuale differenza tra valore dei voucher e il costo della prenotazione, con una qualsiasi carta di credito.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b-slide" v-if="scalapayEnabled">
                                      <div class="content">
                                        <div class="b-card">
                                          <div class="b-card--thumb" style="background-image:url('../../../../assets/sistemi-di-pagamento/5-scalapay.png')"></div>
                                          <div class="b-card--text">
                                            <h5 class="b-card--title">Scalapay</h5>
                                            <div class="b-card--link mt-0">
                                              <p class="text-left mt-3">
                                                Con Scalapay puoi pagare qualsiasi prenotazione di valore non superiore a 1500 € in 3 rate mensili senza interessi.
                                                <br><br>
                                                Come? Scegli il tuo hotel e il tuo soggiorno e nella pagina di check-out seleziona il metodo di pagamento Scalapay. Verrai portato sul sito Scalapay per una velocissima registrazione.
                                                <br><br>
                                                La prima rata verrà addebitata subito sulla tua carta di credito. Le altre due, di pari valore, nei prossimi due mesi.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="b-slide" v-if="promoshoppingEnabled">
                                        <div class="content">
                                            <div class="b-card">
                                                <div class="b-card--thumb" style="background-image:url('../../../../assets/sistemi-di-pagamento/3-promoshopping.png')"></div>
                                                <div class="b-card--text">
                                                    <h5 class="b-card--title">Promoshopping</h5>
                                                    <div class="b-card--link mt-0">
                                                        <p class="text-left mt-3">
                                                            Nella pagina di check-out, dopo avere inserito i tuoi dati, troverai un bottone con il logo Promoshopping.
                                                            <br><br>
                                                            Inserisci qui il codice Promoshopping a 16 cifre in tuo possesso.
                                                            <br><br>
                                                            Puoi cumulare anche più di un codice inserendoli ad uno ad uno.
                                                            <br><br>
                                                            Potrai quindi pagare la prenotazione interamente con Promoshopping o di pagare l'eventuale differenza tra valore dei codici e il costo della prenotazione, con una qualsiasi carta di credito.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b-slide">
                                        <div class="content">
                                            <div class="b-card">
                                                <div class="b-card--thumb" style="background-image:url('../../../../assets/sistemi-di-pagamento/4-buono-sconto-vivamod.png')"></div>
                                                <div class="b-card--text">
                                                    <h5 class="b-card--title">Buono sconto</h5>
                                                    <div class="b-card--link mt-0">
                                                        <p class="text-left mt-3">
                                                            Nella pagina di check-out, dopo avere inserito i tuoi dati, troverai un bottone con il logo {{site.brand}} Buono sconto.
                                                            <br><br>
                                                            Inserisci il codice a 16 cifre in tuo possesso. Puoi cumulare anche più di un codice inserendoli ad uno ad uno.
                                                            <br><br>
                                                            Potrai quindi pagare la prenotazione interamente con il tuo Buono sconto oppure potrai pagare l'eventuale differenza tra valore dei codici e il costo della prenotazione, con una qualsiasi carta di credito.
                                                            <br><br>
                                                            Normalmente i Buoni sconto sono cumulabili e frazionabili.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slick>
                            </div>

                            <div class="b-left" id="b-left">
                                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M80 40.0001C80 62.0097 62.0097 80.0001 40.0001 80.0001C17.9905 80.0001 0 62.0097 0 40.0001C0 17.9905 17.9905 0 40.0001 0C62.0097 0 80 17.9905 80 40.0001Z" fill="#CCCCCC"/>
                                    <g filter="url(#filter0_dleft)">
                                    <path d="M70.4305 40.0001C70.4305 56.8422 56.8422 70.4308 40.0001 70.4308C23.1579 70.4308 9.56934 56.8422 9.56934 40.0001C9.56934 23.1579 23.1579 9.5694 40.0001 9.5694C56.8422 9.5694 70.4305 23.3493 70.4305 40.0001Z" fill="white"/>
                                    </g>
                                    <path d="M45.1672 26.8429C45.9328 26.8429 46.507 27.0343 47.0811 27.6084C48.0381 28.5654 48.0381 30.2879 47.0811 31.4362L38.4687 40.0487L47.0811 48.6611C48.0381 49.6181 48.0381 51.3405 47.0811 52.4889C46.1242 53.4458 44.4017 53.4458 43.2534 52.4889L32.727 41.9625C31.7701 41.0056 31.7701 39.2831 32.727 38.1348L43.2534 27.6084C43.8275 27.0343 44.4017 26.8429 45.1672 26.8429Z" fill="#39414E"/>
                                    <defs>
                                    <filter id="filter0_dleft" x="7.56934" y="7.5694" width="66.8612" height="66.8614" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="1" dy="1"/>
                                    <feGaussianBlur stdDeviation="1.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                            </div>
                            <div class="b-right" id="b-right">
                                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M80 40.0001C80 62.0097 62.0097 80.0001 40.0001 80.0001C17.9905 80.0001 0 62.0097 0 40.0001C0 17.9905 17.9905 0 40.0001 0C62.0097 0 80 17.9905 80 40.0001Z" fill="#CCCCCC"/>
                                    <g filter="url(#filter0_dright)">
                                    <path d="M70.4305 40.0001C70.4305 56.8422 56.8422 70.4308 40.0001 70.4308C23.1579 70.4308 9.56934 56.8422 9.56934 40.0001C9.56934 23.1579 23.1579 9.5694 40.0001 9.5694C56.8422 9.5694 70.4305 23.3493 70.4305 40.0001Z" fill="white"/>
                                    </g>
                                    <path d="M34.6413 53.2066C33.8758 53.2066 33.3016 53.0152 32.7275 52.441C31.7705 51.4841 31.7705 49.7616 32.7275 48.6133L41.3399 40.0008L32.7275 31.3884C31.7705 30.4314 31.7705 28.7089 32.7275 27.5606C33.6844 26.6037 35.4069 26.6037 36.5552 27.5606L47.0816 38.0869C48.0385 39.0439 48.0385 40.7664 47.0816 41.9147L36.5552 52.441C35.9811 53.0152 35.4069 53.2066 34.6413 53.2066Z" fill="#39414E"/>
                                    <defs>
                                    <filter id="filter0_dright" x="7.56934" y="7.5694" width="66.8612" height="66.8614" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="1" dy="1"/>
                                    <feGaussianBlur stdDeviation="1.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>   

        <section class="section--card-cofanetti mb-0" data-aos="fade-up" id="scroll">
            <b-container fluid>
                <h2 class="title text-center h2 mb-0 pb-0">Carte di credito e molto altro</h2>
                <div class="content mt-0">
                    <img class="bg" src="assets/sistemi-di-pagamento/vivamod-bannerone-viaggi-incentive.png" />
                    <!--<img class="cards" src="assets/cards.png" />-->
                </div>
                <!--<div class="link"><a href="#" class="btn">Scopri di più</a></div>-->
                <div class="drop">
                    <img class="bg" src="assets/card-cofanetti-bg.svg" />
                    <div class="text">
                        Una volta selezionato l'hotel, l'appartamento o il B&B che vuoi prenotare troverai nella pagina di checkout tutte le possibili opzioni di pagamento
                    </div>
                </div>
            </b-container>
        </section>

        <section class="text-center mb-4 pb-4 mt-0 pt-0">
            <a @click="mixinGoTo('homepage',{})" class="btn white uppercase text-center">Scopri le disponibilità</a>
        </section>

        <br><br><br>
    </div>
</template>

<script>
    import Slick from 'vue-slick'
    //import FormContatti from '@/components/blocks/form-contatti'
    import ScrollTo from '@/components/atoms/scroll-to'
    import Vue from "vue";
    //import SvgChevronLeft from '@/components/blocks/svg/chevron-left'
   // import SvgChevronRight from '@/components/blocks/svg/chevron-right'
    
    export default {
        name: 'sistemi-di-pagamento',
        components: {
            Slick,
            //FormContatti,
            ScrollTo,
            //SvgChevronLeft,
            //SvgChevronRight,
        },
        data()
        {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots:false,
                    fade:true,
                    arrows:true,
                    autoplay:true,
                    speed: 1500,
                    autoplaySpeed: 5500,
                    prevArrow: '#hero-slider-prev',
                    nextArrow: '#hero-slider-next',
                    responsive: [{
                        /*
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                        */
                    }]
                },
                bSlickOptions: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots:false,
                        //fade:true,
                        //arrows:false,
                        //autoplay:true,
                        speed: 500,
                        //autoplaySpeed: 7500,
                        prevArrow: '#b-left',
                        nextArrow: '#b-right',
                        responsive: [
                            {
                            breakpoint: 1140,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                }
                            },
                            {
                            breakpoint: 600,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                }
                            }
                        ]
                },
                site: Vue.prototype.$config.site,
                etcEnabled: (Vue.prototype.$config.guiSettings.integrations.etc.enabled)? true : false,
                promoshoppingEnabled: (Vue.prototype.$config.guiSettings.integrations.promoshopping.enabled)? true : false,
                scalapayEnabled: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
            }
        },
        methods: {
        },
        mounted()
        {
        }
    }
</script>

<style lang="scss">
    
    
    



    // stili ereditati dal sito business
    .business
    {
        ul
        {
            padding:0;
        }

        a
        {
            transition:$transition-base;
            text-decoration: none;
            cursor: pointer;
        }

        strong,b
        {
        font-weight:$font-weight-bold;
        }

        .nounderline
        {
        text-decoration: none;
        &:hover
        {
            text-decoration: none;
        }
        }

        a.router-link-active:hover
        {
        text-decoration:none;
        }

        .cursor-pointer
        {
        cursor:pointer;
        }

        .pointer
        {
            cursor:pointer;
        }

        .uppercase
        {
        text-transform:uppercase;
        }

        .underline
        {
        text-decoration: underline;
        }

        @include media-breakpoint-down(xl)
        {
        button,
        input,
        .dropdown,
        .custom-select
        {
            font-size: $font-size-sm !important;
        }
        }



        @each $key,$color in $theme-colors
        {
            .#{$key}
            {
                color:$color;
            }
        }


        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6
        {
            color:$tertiary;
            font-weight: 500;
        }

        .h1
        {
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            margin-bottom:0.5rem;   
            @include media-breakpoint-up(sm) 
            {
                font-size: 36px;
            }
            @include media-breakpoint-up(xxl) 
            {
                font-size: 68px;
                margin-bottom:1rem;
            }
        }

        .h2
        {
            font-size: 28px;
            margin-bottom:20px;
            line-height: 100%;
            @include media-breakpoint-up(lg)
            {
                margin-bottom:30px;
                font-size: 55px;
            }
        }

        .h3
        {
            font-size:11px;
            line-height:140%;
            margin-bottom: 0.5rem;
            @include media-breakpoint-up(md) 
            {
                font-size: 14px;
            }
            @include media-breakpoint-up(xxl) 
            {
                font-size: 18px;
            }
        }

        .h4
        {
            font-size: 24px;
            @include media-breakpoint-up(md)
            {
                font-size: 20px;
            }
            @include media-breakpoint-up(xl)
            {
                font-size: 32px;
            }
        }

        .h5
        {
            font-size:18px;
            margin-bottom:1rem;
        }


        .white
        {
        color:$white !important;
        }

        .blue
        {
        color:$blue !important;
        }

        .dark-blue
        {
        color:$dark-blue !important;
        }


        section,.section
        {
            padding:40px 0;
            @include media-breakpoint-up(lg)
            {
                padding:100px 0;
            }

            &--title
            {
                @extend .h2;
            }

            &--subtitle
            {
                @extend .h3;
                //color:#157FFB;
                color:$light-gray;
                @include media-breakpoint-up(lg)
                {
                    margin-bottom:30px;
                }
            }
        }

        img,svg
        {
            max-width: 100%;
        }


        .boxed-text
        {
            padding:$grid-gutter-width/4;
            margin: 40px auto;
            .title
            {
                font-size: 16px;
                color:$dark-blue;
            }
            .text
            {
                //font-size: 14px;
            }
            @include media-breakpoint-up(sm)
            {
                max-width:500px;
            }
            @include media-breakpoint-up(lg)
            {
                max-width:600px;
                .title
                {
                    font-size: 20px;
                    color:$dark-blue;
                }
                .text
                {
                    //font-size: 16px;
                }        
            }
            @include media-breakpoint-up(xl)
            {
                margin: 80px auto;
                max-width:800px;
                .title
                {
                    font-size: 28px;
                    color:$dark-blue;
                }
                .text
                {
                    //font-size: 20px;
                }        
            }
            @include media-breakpoint-up(xxl)
            {
                max-width:900px;
                margin: 100px auto;
            }
        }






        .extraQuote
        {
            color:$white;
            font-size: 12px;
            line-height: 140%;
            position:relative;
            margin-top:$grid-gutter-width/2;
            @include media-breakpoint-down(xs)
            {
                .text
                {
                    top:50% !important;
                    font-size: 10px !important;
                    margin-bottom:0 !important;
                    br
                    {
                        display: none;
                    }
                }
                .cta
                {
                    display: none !important;
                }
            }
            @include media-breakpoint-up(lg)
            {
                font-size: 20px;
                margin-top:$grid-gutter-width;
            }
            .image
            {
                width:100%;
                height:100%;
                svg
                {
                    height: auto;
                    width:100%;
                    max-width: 1200px;
                }
            }
            .text
            {
                @extend .h5;
                color:$white;
                font-weight: 400;
                position:absolute;
                text-align:left;
                top:45%;
                left:37.5%;
                transform:translateY(-50%);
                width:50%;
                @include media-breakpoint-up(lg)
                {
                    width:42.5%;
                }
            }
        }






        .b-cards
        {
            display: flex;
            flex-wrap: wrap;
            //align-items: center;
            justify-content: center;
            max-width: 1400px;
            margin:auto;
            .b-card
            {
                flex:315px;
                max-width: 100%;
                padding:0 0 0 0;
                display: flex;
                flex-direction: column;
                &--thumb
                {
                    height: 200px;//300px;
                    background-size: contain;//cover;
                    background-position: top center;// center center;
                    z-index: 1;
                    transform:translateY(20%);//(15%);
                }
                &--text
                {
                    flex:1;
                    background:$tertiary;
                    position:relative;
                    z-index: 2;
                    border-radius: $border-radius;
                    padding:20px;
                    @include media-breakpoint-up(md)
                    {
                        padding:30px;
                    }
                }

                @include media-breakpoint-up(sm)
                {
                    max-width: 340px;
                    padding:0 10px 0 10px;
                    &--thumb
                    {
                        transform:translateY(23%);
                    }
                }
                @include media-breakpoint-up(md)
                {
                    max-width: 400px;
                    flex:300px;
                    &--thumb
                    {
                        transform:translateY(27%);
                    }                    
                }
                @include media-breakpoint-up(lg)
                {
                    max-width: 440px;
                    flex:400px;
                    &--thumb
                    {
                        height:275px;
                    }                    
                }

                @include media-breakpoint-up(xl)
                {
                    padding:0px 30px 0px;
                    flex:440px;
                    max-width: 480px;
                    &--thumb
                    {
                        //height:275px;
                    }
                }

                &--title
                {
                    color:$white !important;
                    @extend .h4;
                    margin:0;
                    &:before
                    {
                        content:"";
                        position:absolute;
                        left:0;
                        top:30px;
                        width:8px;
                        height:38px;
                        background:#FDC500;
                    }
                }
                &--link
                {
                    //@extend .h5;
                    color:$white;//$light-gray;
                    margin-bottom:0;
                    margin-top:2rem;
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    cursor:pointer;
                    svg
                    {
                        width:40px;
                        height:auto;
                        transition:$transition-base;
                    }
                    @include media-breakpoint-up(md)
                    {
                        min-height: 60px;
                    }

                }
                cursor:pointer;
                &:hover
                {
                    svg
                    {
                        transform:translateX(10px);
                    }
                }
            }    
        }





        .b-slider
        {
            padding-left: 60px;
            padding-right: 60px;
            max-width: 1340px;
            margin:auto;
            position: relative;
            //border:1px solid red;
            .b-left,.b-right
            {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                width: 40px;
                height:auto;
                cursor:pointer;
                path
                {
                    stroke:none !important;
                }
                @include media-breakpoint-up(md)
                {
                    width: 60px;
                }
            }
            .b-left
            {
                left:0;
            }
            .b-right
            {
                right:0;
            }
            .b-slides
            {
                //border:1px solid green;
            }
            .b-slide
            {
                width: 360px;
                max-width: 100%;
                margin:auto;
                .content
                {
                    position: relative;
                    //padding:0 20px;
                }
                .screen
                {
                    position:absolute;
                    //width:calc(100% - 90px);
                    //left:42px;
                    width:86.5%;
                    left:6%;
                    height: auto;
                    top:50%;
                    transform:translateY(-50%);
                    border-radius: $border-radius;
                }
            }
        }





        .section--card-with-background
        {
            position:relative;
            .floatingImg
            {
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:auto;
            }
            .content
            {
                position:relative;
                z-index:1;
                padding-left:$grid-gutter-width/4;
                @include media-breakpoint-up(sm)
                {
                    padding:0 $grid-gutter-width/4;
                    display:flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    max-width: 1000px;
                    margin: auto; 
                }
                @include media-breakpoint-up(lg)
                {
                    max-width: 1200px;
                    //padding-top: 60px;
                }
                @include media-breakpoint-up(xl)
                {
                    max-width: 1400px;
                    //padding-top: 60px;
                }
            }
            .card
            {
                background:#F8F9FA;
                border-radius: $border-radius;
                padding:$grid-gutter-width/2;
                margin:$grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2;
                .title
                {
                    @extend .h4;
                    margin-bottom:20px;
                    color:$dark-blue;
                    //font-size: 18px;
                }
                p
                {
                    margin-bottom:0;
                }
                ul
                {
                    margin-left: 1rem;
                    margin-top:0.5rem;
                }
                @include media-breakpoint-up(xl)
                {
                    //font-size: 20px;
                    padding:$grid-gutter-width;
                    .title
                    {
                        //font-size: 28px;
                        margin-bottom:26px;
                    }    
                }    
            }
            .icon
            {
                width:60px;
                height:60px;
                position:absolute;
                left:0;
                top:0;
                transform:translateY(-50%) translateX(-50%);
                @include media-breakpoint-up(xl)
                {
                    width:100px;
                    height:100px;
                }
            }
            @include media-breakpoint-down(md)
            {
                //font-size: 14px;
            }
        }




        .section--card-cofanetti
        {
            text-align: center;
            .title
            {
                color:$dark-blue;
                margin-bottom:40px;
            }
            .content
            {
                position:relative;
                text-align: center;
                .bg
                {

                }
                .cards
                {
                    position: absolute;
                    top:2%;
                    left: 0;
                    right: 0;
                    width:auto;
                    margin:auto;
                    max-height:100%;
                }
            }
            .link
            {
                .btn
                {
                    display: inline-flex;
                }
                margin:40px auto;
            }
            .drop
            {
                position:relative;
                margin-bottom:40px;
                transform:translateX(-#{$grid-gutter-width/2});
                text-align: left;
                .bg
                {
                    
                }
                .text
                {
                    color:$white;
                    position:absolute;
                    top:50%;
                    left:15%;
                    width:70%;
                    height:80%;
                    transform:translateY(-30%);
                }
            }

            @include media-breakpoint-up(sm)
            {
                .container-fluid
                {
                    position:relative;
                }
                .content
                {
                    .bg
                    {
            
                    }
                    .cards
                    {
                        position: absolute;
                        top:-5%;
                        left: auto;
                        right: -2.5%;
                        width:auto;
                        margin:auto;
                        height: auto;
                        max-height: none;
                        width:75%
                    }    
                }
                .drop
                {
                    position:absolute;
                    bottom: -40px;
                    left:0;
                    max-width: 340px;
                }
                .link
                {
                    position: absolute;
                    bottom:40px;
                    right:60px;
                }
                margin-bottom:100px;
            }
            @include media-breakpoint-up(lg)
            {
                .content
                {
                    padding-top:40px;
                    .bg
                    {
            
                    }
                    .cards
                    {
                        top:-5%;
                        right: -2.5%;
                        width:85%
                    }    
                }
                .drop
                {
                    bottom: -20px;
                    left:0;
                    max-width: 380px;
                    margin-bottom: 0;
                    .text
                    {
                        color:$white;
                        position:absolute;
                        top:50%;
                        left:20%;
                        width:70%;
                        height:80%;
                        transform:translateY(-30%);
                    }    
                }
                .link
                {
                    bottom:40px;
                    right:60px;
                }
                margin-bottom:100px;
            }
            @include media-breakpoint-up(xl)
            {

                .content
                {
                    padding-top:80px;
                    max-width: 1400px;
                    margin:auto;    
                    .cards
                    {
                        top:0;
                        right: -2.5%;
                        width:85%
                    }    
                }

                .drop
                {
                    max-width: 550px;
                    .text
                    {
                        height: auto;
                        top:50%;
                        transform:translateY(-50%);
                        width:65%;
                    }
                }

                .link
                {
                    right:15%;
                    bottom:60px;
                }
            }

            @include media-breakpoint-up(xxl)
            {
                .link
                {
                    right:15%;
                    bottom:100px;
                }
            }
        }


        .section--data-insight
        {
            .content
            {
                position: relative;
                background:#F8F9FA;
                border-top-left-radius: $border-radius-lg;
                border-top-right-radius: $border-radius-lg;
                padding:50px 50px;
                .title
                {
                    color:$dark-blue;
                }
                .bg
                {
                    display: none;
                }    
            }
            .image
            {
                min-height: 300px;
                background-size: cover;
                background-position: center center;
                border-bottom-left-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
            }
            @include media-breakpoint-up(sm)
            {
                .col-sm-6
                {
                    &:first-child
                    {
                        padding-right:0;
                    }
                    &:last-child
                    {
                        padding-left:0;
                    }
                }
                .content
                {
                    border-top-right-radius: 0;
                    border-top-left-radius: $border-radius-lg;    
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: $border-radius-lg; 
                    height: 100%;
                    padding:70px 60px 70px 70px;
                    .bg
                    {
                        display: inline;
                        position: absolute;
                        left:-$grid-gutter-width;
                        top:-40px;
                        width:25%
                    }
                }
                .image
                {
                    border-top-left-radius: 0;
                    border-top-right-radius: $border-radius-lg;    
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: $border-radius-lg;   
                    height: 100%; 
                }
            }

            @include media-breakpoint-up(xl)
            {
                max-width: 1400px;
                margin:auto;
                .content
                {
                    .bg
                    {
                        left:-$grid-gutter-width;
                        top:-60px;
                        width:25%;
                        max-width:140px;
                    }
                    padding:100px 80px 90px 100px;
                }
                .image
                {
                    border-top-left-radius: 0;
                    border-top-right-radius: $border-radius-lg;    
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: $border-radius-lg;   
                    height: 100%; 
                }    
            }
        }


        .section--prefooter
        {
            background:rgba($dark-blue,0.9);
            color:$white;
            .subtitle
            {
                color:$cta;
                margin-bottom:20px;
            }
            .content
            {
                padding:60px 0;
            }
            .col-sm-6:last-child
            {
                padding-left:0;
                padding-right:0;
            }
            .image
            {
                background-size: cover;
                background-position: top center;
                min-height: 400px;
                height: 100%; 
            }  
            padding-bottom:0;
            padding-top:0;
            margin-bottom:0px;  

            .text
            {
                font-style:italic;
            }

            @include media-breakpoint-up(sm)
            {
                overflow: hidden;
                .image
                {
                    width:calc(100% + 20px);
                    margin-right:-20px;
                }      
            }

            @include media-breakpoint-up(xl)
            {
                .content
                {
                    margin:100px auto;
                    max-width: 600px;
                }
            }
        }

        .scrollTo
        {
            position: absolute;
            bottom:30px;
            margin:auto;
            left:0;
            right:0;
            text-align: center;
            @include media-breakpoint-down(xs)
            {
                bottom:0;
            }
        }

    }

    // stili specifici della pagina
    #sistemi-di-pagamento
    {
        .slick-arrow
        {
            background:transparent;
        }
        .hero-drops
        {
            width:100%;
            overflow:hidden;
            max-width:1920px;
            position:relative;

            .drop-gray-1
            {
                position:absolute;
                top:46px;
                left:25%;
                width:46px;
                height:auto;
            }

            .drop-gray-2
            {
                position: absolute;
                top: 75%;
                left: 40%;
                width: 40px;
                height: auto;
            }

            .slider
            {
                position:relative;
                &--nav
                {
                    position:absolute;
                    padding:5px;
                    bottom:0;
                    display:flex;
                    .arrow
                    {
                        cursor:pointer;
                        transition:$transition-base;
                        &:hover
                        {
                            opacity:0.7;
                        }
                        path
                        {
                            stroke:$secondary;
                        }
                    }
                    @include media-breakpoint-up(lg)
                    {
                        right:0;
                        bottom:60px;
                        left:auto;
                        .arrow
                        {
                            padding:10px 20px;
                            svg
                            {
                                height:auto;
                                width:40px;
                            }
                        }
                    }
                }
            }
            .slide
            {
                //background-size: contain;
                //background-position: center center;
                //min-height: 200px;
                width:100%;
                //border:1px solid red;
                display:flex !important; //altrimenti slick, as usual, la sovrascrive con inline-block
                justify-content: flex-end;
            }
            .image
            {
                width:55%;
                position:relative;
                margin:30px 0;
                transform:translateX((5%))
            }
            .thumb
            {
                height: auto;
                width:100%;
            }
            .caption
            {
                position:absolute;
                top:50%;
                left:0;
                width:55%;
                //transform:translateY(-50%);
                padding:$grid-gutter-width/6;
                //border:1px solid green;
            }
            .small
            {
                color:$dark-blue;
                display:none;
                //font-weight: 400;
            }
            .text
            {
                //margin:0.25rem 0;
                color:$tertiary;
            }

            @include media-breakpoint-up(sm)
            {
                margin-bottom:80px;
                .drop-gray-1
                {
                    width:65px;
                    left:18.5%;
                    top:26.5%;
                }
                .drop-gray-2
                {
                    top: 82%;
                    left: 40%;
                    width: 60px;
                }
                .slider
                {

                }
                .slide
                {
                }
                .image
                {
                    width:60%;
                    margin:0;
                    transform:translateX(5%) translateY(-3.5%);
                }
                .thumb
                {
                }
                .caption
                {
                    padding: 0;
                    padding-left:$grid-gutter-width/2;
                    font-size:16px;
                    top:auto;
                    bottom:0;
                    width:55%;
                }
                .small
                {
                    display:block;
                    //max-width:220px;
                }
                .text
                {
                    margin:0.5rem 0;
                }
            }

            @include media-breakpoint-up(lg)
            {
                .drop-gray-1
                {
                    width: 160px;
                    left: 5.5%;
                    top: 0;
                }
                .drop-gray-2
                {
                    top: 80%;
                    left: 30%;
                    width: 80px;
                }
                .caption
                {
                    bottom:auto;
                    top:50%;
                    transform: translateY(-30%);
                    width:50%;
                }
                .small
                {
                    max-width:400px;
                    &:first-child
                    {
                        bottom:100%;
                    }
                    &:last-child
                    {
                        top:100%;
                    }
                }
                .text
                {
                    //line-height:120%;
                    margin:1rem 0;
                    //font-size:36px;
                    //max-width:400px;
                }                    
            }

            @include media-breakpoint-up(xxl)
            {

                .slider
                {

                }
                .slide
                {
                }
                .image
                {
                    //width:60vw;
                    //transform:none;
                    max-width:1200px;
                    top:0px;
                }
                .thumb
                {
                    top:4px;
                }
                .caption
                {
                    padding:0;
                    margin:0 $grid-gutter-width*2;
                    //font-size:16px;
                    transform:translateY(-50%);
                }
                .small
                {
                    max-width:600px;
                    &:first-child
                    {
                        bottom:100%;
                    }
                    &:last-child
                    {
                        top:100%;
                    }
                }
                .text
                {
                    //line-height:120%;
                    margin:1rem 0;
                    //font-size:36px;
                    max-width:500px;
                }                    
            }            
        }

        @include media-breakpoint-down(xs)
        {
            .hero-drops .text
            {
                font-size: 12px;
            }
            .hero-drops .small
            {
                display:none;
            }
        }

        .extraQuote
        {
            margin-top:0;
            padding-top:0;
            padding-bottom:0 !important;
            br + br
            {
                display:none;
            }
            @include media-breakpoint-up(sm)
            {
                br + br
                {
                    display:block;
                }
            }
            .cta
            {
                display:block;
                font-size:90%;
                margin-bottom: 0;
                margin-top:10px;
                @include media-breakpoint-up(sm)
                {
                    margin-top:30px;
                }
            }
            .text
            {
                top:38%;
                left:37.5%;
                transform:translateY(-50%);
                width:50%;
                @include media-breakpoint-down(sm)
                {
                    font-size: 11px;
                }
                @include media-breakpoint-up(sm)
                {
                    top:42%;
                }
                @include media-breakpoint-up(lg)
                {
                    top:40%;
                }
                @include media-breakpoint-up(xl)
                {
                    
                    left:42%;
                    width:30.5%;
                    top:50%;
                }
            }
        }   

        section.boxed-text
        {
            margin-bottom:140px !important;
            .text
            {
            }
        }
        
        .credito-travel
        {
            margin-bottom:0 !important;
            .title
            {
                color:$dark-blue;
                text-align: center;
                position:absolute;
                top:30%;
                padding:0 4%;
            }

            .drop
            {
                margin-top:-40px;
            }

            @include media-breakpoint-up(md)
            {
                .title
                {
                    width:100%;
                }
            }
            @include media-breakpoint-up(xl)
            {
                .title
                {
                    width:100%;
                }
                .drop
                {
                    max-width: 0px;
                    bottom:80px;
                }
            }
        }

        .section--card-with-background
        {
            @include media-breakpoint-up(sm)
            {
                .card
                {
                    width:calc(50% - #{$grid-gutter-width});
                }

                .card:nth-child(3)
                {

                }

                .card:nth-child(2),
                .card:nth-child(4)
                {
                    transform:translateY(15%);
                }
            }
            @include media-breakpoint-up(lg)
            {
                padding-top:280px;
            }
        }

        .section--prefooter
        {
            .image
            {
                background-position: top center;
            }
        }

        .b-cards
        {
            display:block;
            max-width:1600px;
            .slick-track
            {
                display:flex;
            }
            .slick-slide
            {
                height:inherit;
                min-height:100%;
            }
            .slick-slide > div
            {
                height:inherit;
                min-height:100%;
                display: flex;
            }
            .b-slide
            {
                height:inherit;
                flex:1;
                margin:0;
            }
            .content,
            .b-card
            {
                height:100%;
                cursor:pointer;
            }
            .b-card
            {
                display:flex;
                flex-direction: column;
                &--text
                {
                    flex:1;
                }
            }
            .b-card--text
            {
                margin-top:-20px;
            }
            @include media-breakpoint-down(md)
            {
                .b-card--link
                {
                    //font-size: 12px;
                }
                .b-card--text
                {
                    margin-top:-60px;
                }
            }
        }


        .credito-travel
        {
            padding-top:0 !important;
            margin-bottom:0 !important;
            .title
            {
                color:$dark-blue;
                text-align: center;
                position:absolute;
                top:30%;
                padding:0 4%;
                font-weight: bold;
            }

            .drop
            {
                margin-top:-40px;
            }

            @include media-breakpoint-up(md)
            {
                .title
                {
                    width:100%;
                }
            }
            @include media-breakpoint-up(xl)
            {
                .title
                {
                    width:100%;
                }
                .drop
                {
                    max-width: 540px;
                    bottom:80px;
                }
            }
        }        

        .prods
        {
            //color:#9D9D9C;
            .dot
            {
                max-width: 80px;
                @include media-breakpoint-up(lg)
                {
                    max-width: 120px;
                    transform:translateY(-70%);
                }
            }
            .image
            {
                //position:absolute;
                //left:50%;
                //top:75%;
                margin-top:40px;
            }
            .btn
            {
                position:absolute;
                bottom:10%;
                right:10%;
            }
        }

        .yellow .box *
        {
            color:#333333;
        }
    }
</style>
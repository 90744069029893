<template>
    <div class="contacts">

        <!--<h1 class="title">Inviaci un messaggio</h1>
        <b-form>
            <div class="field-container">
                <b-form-input v-model="name" :class="nameCheck() ? 'valid' : ''" id="input-name" type="text"></b-form-input>
                <label class="form-label">Nome *</label>
            </div>
            <div class="field-container">
                <b-form-input v-model="email" :class="emailCheck() ? 'valid' : ''" id="input-email" type="email"></b-form-input>
                <label class="form-label">Indirizzo email *</label>
            </div>
            <div class="field-container">
                <b-form-input v-model="phone" :class="phoneCheck() ? 'valid' : ''" id="input-phone" type="text"></b-form-input>
                <label class="form-label">Telefono</label>
            </div>
            <div class="field-container">
                <b-form-textarea v-model="message" :class="messageCheck() ? 'valid' : ''" id="input-message" type="text"></b-form-textarea>
                <label class="form-label">Scrivi qui il tuo messaggio *</label>
            </div>
            <div class="field-container">
                <b-form-checkbox
                    id="checkbox-privacy"
                    v-model="privacy"
                    name="checkbox-privacy"
                    :value="true"
                    :unchecked-value="false"
                >
                    <span class="privacy">Ho letto ed accettato i termini e condizioni e la privacy policy</span>
                </b-form-checkbox>
            </div>
            <div class="text-center">
                <b-button @click="send()" :disabled="!formCheck()" variant="secondary">Invia</b-button>
            </div>
        </b-form>-->

        <div class="mt-4 mb-4 d-flex justify-content-center">
            <router-link :to="{name: 'homepage'}">
                <BtnBack text="Torna alla Home" />
            </router-link>
        </div>

        <section id="servizi-business">
            <h5 class="text-center">Se invece sei un’azienda...</h5>
            <p class="text-center">
                ... e sei alla ricerca di soluzioni avanzate per
                inserire i viaggi nel welfare aziendale, nelle tue campagne e concorsi a
                premi o nei tuoi programmi fedeltà:
            </p>

            <br>
            <div class="d-flex justify-content-between icons">
                <div class="d-flex">
                    <SvgContactsMail />
                    <div>
                        INVIACI UN'EMAIL A<br>
                        <a :href="'mailto:'+emailBusiness">{{emailBusiness}}</a>
                    </div>
                </div>
                <div class="d-flex">
                    <SvgContactsPhone />
                    <div>
                        CHIAMACI AL:<br>
                        <a :href="'phone:'+phoneNumber">{{phoneNumber}}</a>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import Vue from 'vue'
import SvgContactsPhone from './../../svg/contacts-phone'
import SvgContactsMail from './../../svg/contacts-mail'
import BtnBack from '../../atoms/btn-back'

export default {
    name: 'contacts',
    components:
    {
        SvgContactsPhone, SvgContactsMail, BtnBack
    },
    props:
    {},
    data()
    {
        return {
            valid: this.formCheck(),
            privacy: false,
            name: '',
            email: '',
            phone: '',
            message: '',
            emailBusiness: Vue.prototype.$config.site.emailBusiness,
            phoneNumber: Vue.prototype.$config.site.phoneNumber
        }
    },
    methods:
    {
        send()
        {
            window.alert('manda mail!');
        },
        nameCheck() { return this.name && this.name.length },
        emailCheck() { return this.email && this.email.length },
        phoneCheck() { return this.phone && this.phone.length },
        messageCheck() { return this.message && this.message.length },
        formCheck()
        {
            if(!this.nameCheck()) return false
            if(!this.emailCheck()) return false
            //if(!this.phoneCheck()) return false
            if(!this.messageCheck()) return false
            if(!this.privacy) return false
            return true
        }
    }
}
</script>

<style lang="scss">
    
    
    
    

    .contacts
    {
        padding:0 0.5rem;
        max-width:700px;
        margin:auto;
        button
        {
            min-width: 200px;
        }
        .title
        {
            text-align:center;
        }
        .privacy
        {
            font-weight:400;
            font-size:14px !important;
        }
        .field-container
        {
            margin-bottom:$grid-gutter-width/3*2;
        }
        .form-label
        {
            left:1rem;
            transition:$transition-base;
        }
        *:focus ~ .form-label,
        *.valid ~ .form-label
        {
            //opacity: 0;
            transform:translateY(-2rem);
        }
        .icons
        {
            margin-top:1rem;
            font-weight:600;
            text-transform: uppercase;
            svg
            {
                margin-right:1rem;
            }
            a
            {
                @include customize-color("secondary");
            }
        }
    }
</style>
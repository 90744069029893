<template>
    <div class="testpage" id="test-customizations">
        <b-container>
            <b-row>
                <b-col>
                    <div class="customize-gradient">customize-gradient()</div>
                    <div class="customize-gradient-vertical-important">customize-gradient(vertical, important)</div>
                    <div class="customize-background-color">customize-background-color("secondary") <span class="customize-color">customize-color("secondary")</span></div>
                    <div class="customize-background-color-important">customize-background-color("primary", important) <span class="customize-color-important">customize-color("primary", important)</span></div>
                    <div class="customize-border-color">customize-border-color("primary")  + customize-color("cta")</div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'test-customizations',
        components: {},
        props: {},
        computed: {},
        data()
        {
            return {}
        },
        methods: {
        },
        mounted() {}
    }
</script>

<style lang="scss">

    .testpage
    {
        padding:80px 0;
        @include media-breakpoint-up(lg)
        {
            padding:260px 0 100px 0;
        }
    }

    #test-customizations
    {
        .customize
        {
            padding:1rem;
            margin:0 1rem 1rem 0;
            color:#fff;
            &-gradient
            {
                @extend .customize;
                @include customize-gradient;
            }
            &-gradient-vertical-important
            {
                @extend .customize;
                @include customize-gradient(vertical, important);
            }
            &-background-color
            {
                @extend .customize;
                @include customize-background-color(secondary);
            }            
            &-background-color-important
            {
                @extend .customize;
                @include customize-background-color(primary, important);
            }            
            &-color
            {
                @extend .customize;
                @include customize-color(primary);
            }            
            &-color-important
            {
                @extend .customize;
                @include customize-color(secondary, important);
            }
            &-border-color
            {
                @extend .customize;
                border: width 1px;
                border-style:solid;
                color:#000;
                @include customize-border-color(primary);
                @include customize-color(cta);
            }
        }
    }
</style>
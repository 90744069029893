<template>
    <div class="adv">


    </div>
</template>

<script>

    export default {
        name: 'adv',
        metaInfo(){
          return {
            title: 'Adv',
            meta: [
              { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: 410 }
            ]
          }
        },

        components: {

        },

        data() {
            return {

            }
        },
        mounted() {

          this.$router.push({name: 'homepage'})

        },
        methods:{

        }
    }

</script>

<style lang="scss">
    
    @import '@/scss/_vivamod.scss';
    
</style>
<template>
    <div class="scrollTo" @click="scrollTo()">
        <img src="svg/scroll-to.svg">
    </div>
</template>

<script>
export default {
    name: 'scroll-to',
    components:
    {
    },
    props: {
      selector: {
        type: String,
        default: '#'
      }
    },
    methods:
    {
        scrollTo()
        {
            let element = document.querySelectorAll(this.selector)[0],
                offset = element.offsetTop,
                body = document.getElementsByTagName('body')[0],
                html = document.getElementsByTagName('html')[0]
                  
            body.scrollTo({ top: offset, behavior: 'smooth' });
            html.scrollTo({ top: offset, behavior: 'smooth' });
        }
    }
}
</script>


<style lang="scss">

    .scrollTo
    {
        display:inline-block;
        width:30px;
        @keyframes bounce {
            0%, 20%, 60%, 100%  { transform: translateY(0); }
            40%  { transform: translateY(-20px); }
            80% { transform: translateY(-10px); }
        }    
        animation: bounce 2s infinite ease-in-out;    
        img,svg
        {
            max-width: 100%;
        }
        @include media-breakpoint-up(lg)
        {
            width:60px;
        }
    }
</style>
<template>
    <div id="page" class="faq">

      <b-container>
        <section>
          <h1 class="text-center">Domande frequenti</h1>
          <!--<p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ac turpis egestas maecenas pharetra convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Eget gravida cum sociis natoque penatibus et magnis dis parturient. Sodales ut eu sem integer vitae.
          </p>-->


          <Spinner v-if="loading" />
          <div v-else v-for="(faq, index) in faqs" v-bind:key="index">

            <br/><br/>
            <h3 class="text-center">{{faq.category}}</h3>
            <Accordion :items="faq.items" :accordionId="faq.catcode"/>

          </div>

        </section>
      </b-container>

    </div>
</template>

<script>
  //import Vue from 'vue'
  //import SearchForm from '../../blocks/search-form/search-form'
  import Api from '../../../api'
  import Spinner from '../../atoms/spinner'
  import Accordion from '../../blocks/common/accordion'

  export default {
    name: 'page',
    metaInfo: {
      title: 'Domande frequenti',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Come si prenota, gli strumenti di pagamento, la rimborsabilità, ecc.' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Come si prenota, gli strumenti di pagamento, la rimborsabilità, ecc.' },
      ],
    },
    components: {
        Accordion,
        Spinner,
    },
    data() {
        return {
            loading: true,
            faqs: [],
      }
    },
    mounted() {
      // resetto filtri e categorie eventualmente settati nello state tramite local storage
      this.mixinSendMutation('setAmenities',[])
      this.mixinSendMutation('setCategories',[])

      this.getFaqs()
    },
    methods:{
      filterChanged(){},
      
      submitSearch()
      {
        this.mixinSendMutation('setTriggerSearch',true)
        this.mixinGoTo('search',{})
      },

      getFaqs()
      {
          let _this = this

          Api.getFaqs().then((response) => {
              _this.faqs = response.faqs
              this.loading = false
          })
      }      
    }
  }
</script>

<style lang="scss">
    
    
    
    .faq
    {
        @include media-breakpoint-up(lg)
        {
        }
    }
</style>
<template>
    <b-container>
        <div class="text-center">
            <span v-if="canUseEtc">
                <br><br>
                Attendi, stiamo effettuando la connessione con Edenred...
                <br><br>
                <Spinner />
                <br><br>
            </span>
            <span v-else>
                <br><br>
                Ticket Compliments &reg; non disponibili.
                <br><br><br>
            </span>
        </div>
    </b-container>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import Spinner from '../../atoms/spinner'


export default {
    name: 'ticket-compliments',
    components:
    {
        Spinner
    },
    data()
    {
        return {
            canUseEtc: true      
        }
    },
    mounted()
    {
        if(!this.mixinUseTicketCompliments())
        {
            this.canUseEtc = false
        }


        if(!this.$route.query.code)
        {
            this.canUseEtc = false
            window.console.log('WARNING view /ticket-compliments without etc qs code')
        }

        
        if(this.canUseEtc == true)
        {

          let code = this.$route.query.code
          if(code)
          {
              this.handleLoginCallback(code)
          }

        }else{

          this.mixinGoTo('checkout', {})

        }

    },
    methods:
    {
        handleLoginCallback: function(code)
        {

            let _this = this

            Api
                .getAccessToken({
                  app: Vue.prototype.$config.app,
                  code: code,
                })
                .then((data) => {

                    if(_this.$gtm) _this.$gtm.trackEvent({
                      event: 'customEvent',
                      category: 'partnerBehaviour',
                      action: 'etc',
                      label: 'logged',
                      value: 1,
                    });

                    if(window.LogRocket) window.LogRocket.track('etc_logged')
                    if(window.clarity) window.clarity("set", "etc", "logged")

                    if(this.mixinIsPublic())
                    {
                        window.location.href = '/checkout?token='+data.token
                        return
                    }

                    window.location.href = '/?token='+data.token
                    // oppure direttamente:
                    // window.location.href = data.redirectTo
                })
        }
    }
}
</script>

<style lang="scss">
/*
    
    
    
*/
</style>